import { mapActions, mapGetters, mapState } from "vuex";
import packageJson from "@/package.json";
import * as _ecom from "../service/ecommerce"; // Внедрим нужные функции в window-объект
import { removeQueryParams } from "../util/router-helper";
import { isIndexPage } from "../service/catalog-router/helpers";
import { debounce, throttle } from "../util/helpers";
import cartUtils from "../util/cart";
import { canUseEcommerce } from "../service/ecommerce";

export default {
    data() {
        return {
            key: 0,
            isPage: null,
            currentScrollPosition: 0,
            isCategoryHide: false,
        };
    },
    computed: {
        ...mapState("delivery", ["city", "isCityConfirmed"]),
        ...mapState("meta", ["title"]),
        ...mapGetters("meta", ["meta", "links"]),
        isServer() {
            return !process.client;
        },
        isRouteCatalog() {
            return isIndexPage(this.$router.currentRoute);
        },
        isShowMenuCategory() {
            const hidePages = {
                search: "search",
                landingNewCity: "new_city",
                profile: "user-profile",
                order_history: "user-orders-history",
                rest: "rest",
                cart: "order-step",
                user_bonus_card: "user-bonus_card",
            };
            return !Object.values(hidePages).includes(this.isPage);
        },
        isPageToHideCategory() {
            const pages = [
                {
                    allWidth: true,
                    fullHide: true,
                    route: "bonus_card",
                },
                {
                    allWidth: false,
                    media: "(max-width: 1024px)",
                    fullHide: false,
                    route: this.routeIsCatalog,
                },
            ];
            return pages.find((page) => {
                if (typeof page.route === "string") {
                    return page.route === this.$router.currentRoute.name;
                } else if (typeof page.route === "boolean") {
                    return page.route;
                }
                return false;
            });
        },
        isFullHideCategory() {
            return this.isPageToHideCategory?.fullHide && this.isCategoryHide;
        },
    },
    methods: {
        ...mapActions("modals", ["setModal", "closeModal"]),
        async removeUnnecessaryQueryParams() {
            await removeQueryParams(this.$router, ["change_city", "catalog"]);
        },
        connectGAClientId() {
            const vm = this;
            const ga = window["ga"]; /* eslint-disable-line dot-notation */
            if (ga && typeof ga === "function") {
                ga(function (tracker) {
                    const clientId = tracker.get("clientId") || null; // получаем clientId из Google Analytics
                    document.cookie = "_ga_cid=" + clientId + "; path=/;secure"; // сохраняем cookie в _ga_cid
                    ga("set", "dimension1", clientId); // записываем clientId пользователя в параметр
                    vm.$store.commit("setGAClientId", clientId);
                    console.log(clientId);
                });
            }
        },
        handleScroll() {
            const scrolled = window.scrollY || document.documentElement.scrollTop;
            const hide =
                this.isPageToHideCategory.allWidth || window.matchMedia(this.isPageToHideCategory.media).matches;

            if (scrolled <= 100) {
                this.isCategoryHide = false;
            } else if (hide) {
                const dY = scrolled - this.currentScrollPosition;
                if (Math.abs(dY) < 50) {
                    this.isCategoryHide = dY > 0;
                }
                this.currentScrollPosition = scrolled;
            }
        },

        createHandleScroll() {
            if (this.isPageToHideCategory) {
                document.addEventListener("scroll", this.handleScroll);
            }
        },
        removeHandleScroll() {
            if (this.isPageToHideCategory) {
                document.removeEventListener("scroll", this.handleScroll);
            }
        },
    },
    async mounted() {
        if (process.env.NODE_ENV === "development" && process.client && window) {
            window.g_login = (creds) => this.$store.dispatch("auth/login", creds);
            window.g_logout = () => this.$store.dispatch("auth/logout");
            window.g_fetchProfile = () => this.$store.dispatch("account/fetchProfile");
            window.g_router = this.$router;
            window.g_route = this.$route;

            window.g_store = this.$store;
            window.g_backcall = () => this.$nuxt.$emit("show-backcall");

            window.g_calcCart = () => cartUtils.calculateTotalAmounts(this.$store.state.cart.content);
            window.g_axios = this.$axios;
            window.g_modal = () => {
                this.$store.dispatch("modals/closeModal").then(() => {
                    this.$nextTick(() => {
                        this.$store.dispatch("modals/openModal", {
                            modalName: "ConfirmOrder",
                            modalData: {
                                isCert: true,
                                phone: "9221113344",
                            },
                        });
                    });
                });
            };
        }
        this.createHandleScroll();
        this.connectGAClientId();

        // Добавляем слушатель всех кнопок на клавиатуре для более простого обращения
        // в компонентах через $nuxt.on('нужная кнопка' + '-presed')
        window.addEventListener("keydown", (evt) => {
            this.$nuxt.$emit(`${evt.key}-pressed`, evt);
        });

        await this.removeUnnecessaryQueryParams();
        console.log(packageJson.version);
    },
    beforeDestroy() {
        this.removeHandleScroll();
    },
    head() {
        return {
            title: this.title,
            meta: this.meta,
            link: this.links,
            htmlAttrs: {
                lang: "ru",
                itemscope: "itemscope",
                itemtype: "http://schema.org/WebPage",
            },
        };
    },
    watch: {
        $route: {
            handler(newVal, oldVal) {
                if (canUseEcommerce()) {
                    // eslint-disable-next-line no-undef
                    Ecommerce.trackPageview(newVal.path);
                }

                this.isPage = newVal.name;

                if (process.client) {
                    this.$store.dispatch("meta/onRouteChangeMeta", newVal || undefined);
                }
            },
            immediate: true,
        },
    },
};
