import { ActionTree, GetterTree, Mutation, MutationTree } from "vuex";
import moment from "moment";
import { NuxtCookies } from "cookie-universal-nuxt";
import { DeliveryGettersDeliveryAddress, DeliveryState, DeliveryTimePayload } from "../store_types/delivery.types";
import { RootState } from "../store_types/index.types";
import { cloneDeep } from "../util/clone";
import { isAddressEmpty, isEmptyString, sanitizeHtml } from "../util/helpers";
import {
    DEFAULT_CITY_ID,
    DELIVERY_TIME,
    DELIVERY_TYPE,
    STOP_LIST_MODE_CHECK,
    STOP_LIST_MODE_FAIL,
} from "../util/types";
import cityLinksHelper from "../util/city-links-helper";
import { CitiesStorage } from "../service/storages/cities-storage/index";
import config from "../config";
import { EcommerceYandex } from "../service/ecommerce";
import { City } from "~/util/api.types";
import { DEFAULT_COOKIES_OPTIONS } from "~/service/cookies-helper";
import { isIndexPage } from "~/service/catalog-router/helpers";

const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

export const state: () => DeliveryState = () => ({
    city: null,
    cities: null,
    departments: null,
    desiredTime: null,
    deliveryAddressAdditional: {},
    deliveryAddress: null,
    deliverySelfDepartemnt: null,
    timeType: DELIVERY_TIME.CURRENT,
    currentDate: null,
    currentTime: null,

    specificTimesAvalable: {},
    deliveryAddressComment: "",
    lastUpdateTime: null,
    deliveryLoading: false,
    lastDeliveryUpdate: null,
    isUpdateDeliveryTimeInProgress: false,

    deliveryClosestSelf: null,
    noDelivery: false,
    isClosestSelfShown: false,
    isPromoDeliveryShown: false,

    chosenDeliveryStartScreen: null,
    // chosenAddressStartScreen: null,
    isGeoRequestCompleted: false,

    serverTime: null,
    clientTime: null,
    isCityConfirmed: false,

    mapClickLoading: false,
    selectDeliveryType: null,

    currentGeoPosition: null,
    geoDenied: false,
    cityViaIp: null,

    panelSelectDeliveryClosed: false,
});

export const getters: GetterTree<DeliveryState, RootState> = {
    minDeliveryAmount: (state, getters, rootState, rootGetters) => {
        return getters.isDelivery ? rootGetters.cart.minimum_amount : 0;
    },
    minDeliveryAmountError: (state, getters, rootState, rootGetters) => {
        if (!rootState.cart.content) {
            return false;
        }
        return getters.minDeliveryAmount && getters.minDeliveryAmount > rootState.cart.content.total_amount;
    },
    minDeliveryTime: (state, getters, rootState, rootGetters) => {
        return rootGetters.cart.minimum_delivery_time || rootState.cart.postCartMinimumTime;
    },
    deliveryType: (state, getters, rootState, rootGetters) => {
        return rootGetters["cart/deliveryType"];
    },
    departments: (state, getters, rootState, rootGetters) => {
        return state.city ? state.city.departments : [];
    },
    selfDeliveryDepartments: (state, getters, rootState, rootGetters) => {
        return getters.departments.filter((depart) => depart.selfDelivery == true);
    },
    hasDelivery: (state, getters, rootState, rootGetters) => {
        return true;
    },
    cityWorkTime: (state) => {
        if (!state.city) {
            return "";
        }
        return state.city.workTimeText || "";
    },
    cityWorkTimeHtml: (state, getters) => {
        return getters.cityWorkTime
            .split("\n")
            .map((line) => sanitizeHtml(line).trim())
            .join("<br>");
    },
    cityUrbanPhone: (state) => {
        if (!state.city) {
            return "";
        }
        return state.city.phonesUrban || "";
    },
    subCities: (state, getters, rootState, rootGetters) => {
        return state.city ? state.city.dependentLoc : [];
    },
    deliverySelfDepartemnt: (state, getters, rootState, rootGetters) => {
        if (state.deliverySelfDepartemnt) {
            return state.deliverySelfDepartemnt;
        } else {
            return getters.selfDeliveryDepartments.find((d) => d.id == rootGetters.cart.delivery_department_id) || null;
        }
    },
    deliveryAddress: (state, getters, rootState, rootGetters): DeliveryGettersDeliveryAddress => {
        const address = cloneDeep(state.deliveryAddress || rootGetters.cart.delivery_address || {});

        if (state.deliveryAddressAdditional.flat !== undefined) {
            address.flat = state.deliveryAddressAdditional.flat;
        }

        if (state.deliveryAddressAdditional.entrance !== undefined) {
            address.entrance = state.deliveryAddressAdditional.entrance;
        }

        if (state.deliveryAddressAdditional.domofon !== undefined) {
            address.domofon = state.deliveryAddressAdditional.domofon;
        }

        if (state.deliveryAddressAdditional.floor !== undefined) {
            address.floor = state.deliveryAddressAdditional.floor;
        }

        return address;
    },
    deliveryDate: (state, getters, rootState, rootGetters) => {
        if (rootGetters.cart.delivery_time) {
            return moment.parseZone(rootGetters.cart.delivery_time).format(DEFAULT_DATE_FORMAT);
        } else {
            const d = state.currentDate ? state.currentDate : moment();
            return moment.parseZone(d).format(DEFAULT_DATE_FORMAT);
        }
    },
    deliveryTime: (state, getters, rootState, rootGetters) => {
        let time: any = null;
        if (rootGetters.cart.delivery_time) {
            time = moment.parseZone(rootGetters.cart.delivery_time);
        } else {
            const t = state.currentTime ? state.currentTime : moment().add(15, "minutes");
            time = moment.parseZone(t);
        }
        // console.log(rootGetters.cart.delivery_time, time, time.format("DD/MM/YY HH:mm"));
        return time.isValid() ? time.format("HH:mm") : null;
    },
    deliveryDateTime: (state, getters, rootState, rootGetters) => {
        return rootGetters.cart.delivery_time;
    },
    isSpecificTime: (state, getters, rootState, rootGetters) => {
        return state.timeType == DELIVERY_TIME.SPECIFIC;
    },
    isSpecificTimeSelected: (state) => {
        return state.currentTime != null;
    },
    isSelfDelivery: (state, getters) => {
        return getters.deliveryType == DELIVERY_TYPE.SELF;
    },
    isSelfCarDelivery: (state, getters) => {
        return getters.deliveryType == DELIVERY_TYPE.SELF_CAR;
    },
    isHallDelivery: (state, getters) => {
        return getters.deliveryType == DELIVERY_TYPE.HALL;
    },
    isAnySelfDelivery: (state, getters) => {
        return getters.isSelfDelivery || getters.isSelfCarDelivery;
    },
    isDelivery: (state, getters) => {
        return getters.deliveryType == DELIVERY_TYPE.DELIVERY;
    },
    city: (state, getters, rootState, rootGetters) => {
        return state.city;
    },
    cityId: (state) => {
        return state.city ? state.city.id : 1;
    },
    emptyDeliveryAddress: (state, getters, rootState, rootGetters) => {
        return isAddressEmpty(getters.deliveryAddress);
    },
    emptyDeliverySelf: (state, getters, rootState, rootGetters) => {
        return !getters.deliverySelfDepartemnt || Object.keys(getters.deliverySelfDepartemnt).length === 0;
    },
    allEmptyDeliverySelf: (state, getters, rootState, rootGetters) => {
        return (
            !getters.deliverySelfDepartemnt ||
            Object.keys(getters.deliverySelfDepartemnt).length === 0 ||
            !rootGetters.cart.delivery_department_id
        );
    },
    emptyDelivery: (state, getters, rootState, rootGetters) => {
        return (
            !rootGetters.cart.delivery_type ||
            (getters.deliveryType == DELIVERY_TYPE.DELIVERY && getters.emptyDeliveryAddress) ||
            (getters.deliveryType == DELIVERY_TYPE.SELF && getters.emptyDeliverySelf) ||
            (getters.deliveryType == DELIVERY_TYPE.HALL && getters.emptyDeliverySelf)
        );
    },
    addressMapCoords: (state, getters, rootState, rootGetters) => {
        if (
            getters.deliveryType == DELIVERY_TYPE.DELIVERY &&
            rootGetters.cart.delivery_address &&
            rootGetters.cart.delivery_address.lon &&
            rootGetters.cart.delivery_address.lat
        ) {
            return [rootGetters.cart.delivery_address.lat, rootGetters.cart.delivery_address.lon];
        } else if (
            getters.deliveryType &&
            getters.deliverySelfDepartemnt &&
            getters.deliverySelfDepartemnt.lon &&
            getters.deliverySelfDepartemnt.lat
        ) {
            return [getters.deliverySelfDepartemnt.lat, getters.deliverySelfDepartemnt.lon];
        }
        return null;
    },
    isExternalDelivery: (state, getters, rootState, rootGetters) => {
        return rootGetters.cart && rootGetters.cart.delivery_price && rootGetters.cart.delivery_price > 0;
    },
    isCurrentTimeDisabled(state, getters, rootState) {
        return rootState.errors.list.delivery && rootState.errors.list.delivery.not_working_time;
    },
    getDeliveryClosestSelf: (state, getters, rootState, rootGetters) => {
        return state.deliveryClosestSelf;
    },
    noDesireTime(state): Boolean {
        return state.desiredTime == null;
    },
    noDeliveryTime: (state, getters, rootState, rootGetters) => {
        return !rootState.cart?.content?.delivery_time;
    },
    minutesToDelivery(state, getters, rootState, rootGetters) {
        const defaultValue = 60;

        const deliveryDateTime = rootGetters.cart.delivery_time;
        if (!deliveryDateTime || !state.clientTime || !state.serverTime) {
            return defaultValue;
        }
        const updatedClientTime: number | null = new Date().getTime() + state.clientTime - state.serverTime;
        const deliveryMoment: number = new Date(deliveryDateTime).getTime();
        if (!deliveryMoment || !updatedClientTime) {
            return defaultValue;
        }

        return Math.round((deliveryMoment - updatedClientTime) / 1000 / 60 / 5) * 5;
    },
    isDeliveryAddressCorrect(state, getters, rootState, rootGetters) {
        if (!getters.isDelivery) {
            return true;
        }
        return (
            !!getters.deliveryAddress &&
            Object.keys(getters.deliveryAddress).length > 0 &&
            !!rootGetters.cart.prepare_time &&
            !!rootGetters.cart.delivery_time
        );
    },
    chosenDepartment(state, getters) {
        if (!getters.emptyDelivery && getters.deliverySelfDepartemnt) {
            return getters.departments.find((department) => department.id == getters.deliverySelfDepartemnt.id);
        }
        return null;
    },
    cityMap(state, getters, rootState, rootGetters) {
        return state.city ? state.city.deliveryMap : null;
    },
    // chosenAddressStartScreen(state) {
    //     return state.chosenAddressStartScreen;
    // },
    isGeoRequestCompleted(state) {
        return state.isGeoRequestCompleted;
    },
    getCurrentDayTimeWork(state, getters) {
        const department = getters.departments.find((item) => {
            if (state?.deliverySelfDepartemnt) {
                return item.id === state.deliverySelfDepartemnt.id;
            }
            return false;
        });

        return department;
    },
    getSelectDeliveryType(state, getters) {
        return state.selectDeliveryType || getters.deliveryType;
    },
    currentGeoPosition(state) {
        return state.currentGeoPosition;
    },
    fullEmptyDelivery(state, getters, rootGetters) {
        return getters.allEmptyDeliverySelf && getters.emptyDeliveryAddress && !rootGetters["cart/deliveryAddress"];
    },
    showPanelSelectDelivery(state, getters) {
        return !state.panelSelectDeliveryClosed && getters.fullEmptyDelivery;
    },
};

export const mutations: MutationTree<DeliveryState> = {
    setCity(state, city) {
        state.city = city;
    },
    setCities(state, cities) {
        state.cities = cities;
    },
    setDepartments(state, departments) {
        state.departments = departments;
    },
    setCurrentDateTime(state, payload) {
        state.currentDate = payload && payload.date ? payload.date : moment().format(DEFAULT_DATE_FORMAT);
        state.currentTime = payload && payload.time ? payload.time : null;
    },
    setTimeType(state, type) {
        state.timeType = type;
    },
    setDeliveryAddress(state, address) {
        state.deliveryAddress = address;
    },
    setDeliverySelfDepartment(state, department) {
        state.deliverySelfDepartemnt = department;
    },
    setDeliveryAddressComment(state, comment) {
        state.deliveryAddressComment = comment;
    },
    clearLastUpdateTime(state) {
        state.lastUpdateTime = null;
    },
    setLastUpdateTime(state) {
        state.lastUpdateTime = new Date();
    },
    setSpecificTimesAvalable(state, payload) {
        state.specificTimesAvalable = payload;
    },
    setDeliveryLoading(state, payload: boolean) {
        state.deliveryLoading = payload;
    },
    setDeliveryAddressAdditional(state, address) {
        const values: any = {};

        if (address.flat !== undefined) {
            values.flat = address.flat;
        }

        if (address.entrance !== undefined) {
            values.entrance = address.entrance;
        }

        if (address.domofon !== undefined) {
            values.domofon = address.domofon;
        }

        if (address.floor !== undefined) {
            values.floor = address.floor;
        }

        state.deliveryAddressAdditional = values;
    },
    setLastDeliveryTimeUpdated(state) {
        state.lastDeliveryUpdate = moment();
    },
    setLastDeliveryUpdate(state, value) {
        state.lastDeliveryUpdate = value;
    },
    setUpdateDeliveryTimeInProgress(state, value) {
        state.isUpdateDeliveryTimeInProgress = !!value;
    },
    setDeliveryClosestSelf(state, payload = { payload: null, noDelivery: false }) {
        state.deliveryClosestSelf = payload.payload;
        state.noDelivery = payload.noDelivery;
    },
    setDeliveryDesireTime(state, newTime) {
        state.desiredTime = newTime;
    },
    setClosestSelfShown(state, value: boolean) {
        state.isClosestSelfShown = value;
    },
    setPromoDeliveryShown(state, value: boolean) {
        state.isPromoDeliveryShown = value;
    },
    setDeliveryChoose(state, payload) {
        state.chosenDeliveryStartScreen = payload;
    },
    // setChosenAddressStartScreen(state, payload) { //Не знаю зачем это нужно
    //     state.chosenAddressStartScreen = payload;
    // },
    setGeoRequestCompleted(state, payload) {
        state.isGeoRequestCompleted = payload;
    },
    setServerTime(state, payload) {
        state.serverTime = payload;
    },
    setClientTime(state, payload) {
        state.clientTime = payload;
    },
    setCityConfirmed(state, payload) {
        state.isCityConfirmed = payload;
        if (payload === true) {
            // @ts-ignore
            const cookies: NuxtCookies = this.$cookies;
            cookies.set("city_confirmed", true, DEFAULT_COOKIES_OPTIONS);
        } else {
            // @ts-ignore
            const cookies: NuxtCookies = this.$cookies;
            cookies.set("city_confirmed", false, DEFAULT_COOKIES_OPTIONS);
        }
    },
    setSelectDeliveryType(state, payload) {
        state.selectDeliveryType = payload;
    },

    setCurrentGeoPosition(state, payload) {
        state.currentGeoPosition = payload;
    },
    setGeoDenied(state, payload) {
        state.geoDenied = payload;
    },
    setMapClickLoading(state, val) {
        state.mapClickLoading = val;
    },
    setCityViaIp(state, val) {
        state.cityViaIp = val;
    },
    setPanelSelectDeliveryClosed(state, val) {
        state.panelSelectDeliveryClosed = val;
    },
};

export const actions: ActionTree<DeliveryState, RootState> = {
    setCityId({ state, commit }, cityId: string | number | null = null) {
        const cookies: NuxtCookies = this.$cookies;

        if (!state.cities || !cityId) {
            commit("setCity", null);
            return null;
        }

        cityId = Number(cityId);
        cookies.set("city_id", cityId, DEFAULT_COOKIES_OPTIONS);

        const currentCity: City | undefined = state.cities.find((city: City) => city.id === cityId);

        if (currentCity) {
            commit("setCity", currentCity);
            return;
        }

        const defaultCity: City | undefined = state.cities.find((city: City) => city.id === DEFAULT_CITY_ID);

        if (defaultCity) {
            commit("setCity", defaultCity);
        } else {
            console.warn("Не найден ID города по умолчанию");
            console.error({
                city_id: cityId,
                default_id: DEFAULT_CITY_ID,
                cities: state.cities,
            });
        }
    },
    detectDesiredTime({ commit, rootGetters }) {
        if (!rootGetters.cart) {
            return;
        }
        const minDeliveryMoment = rootGetters.cart.minimum_delivery_time
            ? moment.parseZone(rootGetters.cart.minimum_delivery_time)
            : null;
        const selDeliveryMoment = moment.parseZone(rootGetters.cart.delivery_time);

        if (minDeliveryMoment && selDeliveryMoment <= minDeliveryMoment) {
            return;
        }

        commit("setDeliveryDesireTime", selDeliveryMoment.format());
        commit("setTimeType", DELIVERY_TIME.SPECIFIC);

        commit("setCurrentDateTime", {
            date: selDeliveryMoment.format("YYYY-MM-DD"),
            time: selDeliveryMoment.format("HH:mm"),
        });
    },
    async loadCities({ commit }) {
        commit("setCities", (await new CitiesStorage().get()) || []);
    },
    reloadByCityId({ commit, dispatch }, payload) {
        if (!process.client) {
            return;
        }

        const cityId = payload?.cityId;
        if (!cityId) {
            return;
        }

        let url = payload?.url;

        const params = ["change_city=1"];

        if (isIndexPage(this.$router.currentRoute)) {
            params.push("catalog");
        }

        dispatch("setCityId", cityId);

        const cleanPath = payload.go_base ? "/" : cityLinksHelper.cleanCityPath(this.$router.currentRoute.path);

        if (!url) {
            url = cityLinksHelper.getNuxtLinkToPath(cleanPath, cityId);

            if (url.length === 0) {
                url += "/";
            }

            url += "?" + params.join("&");
        }

        location.href = url;
    },
    async setCity({ commit, dispatch, state, rootGetters }, cityId: number | undefined) {
        const canUseWait: boolean = process.client && this.hasModule("wait");

        if (canUseWait) {
            dispatch("wait/start", "delivery.set_city", { root: true });
        }

        if (!cityId) {
            cityId = DEFAULT_CITY_ID;
        }

        const cityData = {
            city_id: cityId,
            ya_metrika: await EcommerceYandex.yaMetricaData("set-city"),
        };

        try {
            await this.$postCartQueue.add("cart/city", cityData, 1);
        } catch (error: any) {
            console.warn("Ошибка выбора города");
            console.error(error);
            throw error;
        } finally {
            if (canUseWait) {
                dispatch("wait/end", "delivery.set_city", { root: true });
            }
        }
    },
    async getDepartmentsInfo({ state, commit }) {
        await this.$api.get("cities/departments", { city_id: state.city?.id }).then((resp) => {
            if (resp.success) {
                commit("setDepartments", resp.departments);
            }
        });
    },
    checkSelfFasterThanDelivery({ state, commit, dispatch, rootGetters }) {
        if (!rootGetters.cart.delivery_closest_self || !rootGetters.cart.delivery_address) {
            console.log("** no data");
            return;
        }
        if (state.isClosestSelfShown) {
            console.log("** already shown");
            return;
        }

        commit("setClosestSelfShown", true);
        dispatch(
            "modals/openModal",
            {
                modalName: "SelfDeliveryPopup",
                modalData: {
                    departments: rootGetters.cart.delivery_closest_self,
                    deliveryTime: rootGetters.cart.minimum_delivery_time,
                },
            },
            { root: true }
        );
    },
    setCityWithoutReload({ commit, dispatch, rootState }, cityId: number) {
        dispatch("setCityId", cityId);

        this.$router.replace(
            cityLinksHelper.getNuxtLinkToPath(
                cityLinksHelper.cleanCityPath(this.$router.currentRoute.path),
                cityLinksHelper.getCityIdFromVuex(rootState)
            )
        );
    },
    async setDelivery({ state, getters, commit, dispatch, rootGetters, rootState }, payload) {
        dispatch("errors/clearError", "delivery", { root: true });
        if (!payload.is_preserve_delivery_time) {
            dispatch("clearTimeType", null);
        }

        if (payload.force) {
            dispatch("payment/setPaymentType", rootState.payment.currentPaymentType, { root: true });
        } else {
            dispatch("payment/setPaymentType", null, { root: true });
        }

        commit("setDeliveryLoading", true);
        if (!payload.background) {
            dispatch("wait/start", "cart.delivery", { root: true });
        }

        this.$postCartQueue.remove("cart/deliveryTime");

        // Всегда будем проверять стоп-лист по отправлению способа доставки
        payload.check_stop = STOP_LIST_MODE_CHECK;
        payload.is_door_to_door =
            payload.is_door_to_door !== undefined ? payload.is_door_to_door : getters.isDoorToDoor;
        payload.change_city = true;
        try {
            commit("setClosestSelfShown", false);
            commit("setPromoDeliveryShown", false);
            const resp = await this.$postCartQueue.add("cart/delivery", payload, 500);
            commit("setLastUpdateTime");
            commit("setDeliveryClosestSelf", {
                payload: resp.cart.delivery_closest_self,
                noDelivery: false,
            });

            // dispatch('setCartDepartmentId', resp.cart);
            dispatch("checkRemovedProducts", resp);
            dispatch("updateCurrentDateAndTime");

            if (resp.cart.city_id != state.city?.id) {
                dispatch("setCityWithoutReload", resp.cart.city_id);
            }
        } catch (error: any) {
            if (
                error.error_code == "unavailable_address" &&
                error.cart.delivery_closest_self &&
                error.cart.delivery_closest_self.length > 0
            ) {
                commit("setDeliveryClosestSelf", {
                    payload: error.cart.delivery_closest_self,
                    noDelivery: true,
                });
            } else {
                dispatch("errors/addPopupError", { errorTitle: error.error_message }, { root: true });
            }

            if (error.error_code == "invalid_department_delivery") {
                commit("setDeliverySelfDepartment", null);
            }
            dispatch("deliveryChangeError", error);

            if (error.error_code == "not_working_time") {
                dispatch("onNotWorkingTimeError");
            }

            // return Promise.reject();
        } finally {
            commit("setDeliveryAddress", rootGetters.cart.delivery_address);
            commit("setDeliveryLoading", false);
            dispatch("wait/end", "cart.delivery", { root: true });
        }
    },

    cancelSetDelivery({ state, commit, dispatch, rootGetters }) {
        if (state.deliveryLoading) {
            this.$postCartQueue.remove("cart/delivery_");
            commit("setDeliveryAddress", rootGetters.cart.delivery_address);
            commit("setDeliveryLoading", false);
            dispatch("wait/end", "cart.delivery", { root: true });
        }
    },
    // если текущее время недоступно - устновим отложенное время и завтрашнюю дату
    async onNotWorkingTimeError({ dispatch, commit }) {
        await dispatch("setTimeType", { type: DELIVERY_TIME.SPECIFIC });
        const date = new Date();
        if (date.getHours() > 18) {
            commit("setCurrentDateTime", { date: moment().add(24, "hour").format(DEFAULT_DATE_FORMAT) });
        }
    },
    // setDoorToDoor({getters, commit, dispatch}, value) {
    //     commit('cart/setDoorToDoor', value, {root: true});
    //     dispatch('setDelivery', {
    //         delivery_type: getters.deliveryType,
    //         delivery_address: getters.deliveryAddress,
    //         is_door_to_door: !!value,
    //     });
    // },
    setDeliveryAddressComment({ commit, dispatch }, comment) {
        dispatch("errors/clearError", "delivery.self_car", { root: true });
        commit("setDeliveryAddressComment", comment);
    },
    async setDeliverySelfDepartment({ commit, dispatch, getters }, payload) {
        const deliveryType = payload.type || DELIVERY_TYPE.DELIVERY;

        if (payload.department === null) {
            commit("setDeliverySelfDepartment", payload.department);
            return;
        }
        try {
            await dispatch("setDelivery", {
                delivery_type: deliveryType,
                delivery_department_id: payload.department.id,
            });
            commit("setDeliverySelfDepartment", payload.department);
        } catch (err) {
            console.warn("Ошибка выбора подразделения для самовывоза");
            console.error(err);
        }
    },
    setDeliverySelfDepartmentById({ getters, commit, dispatch }, departmentId) {
        const department = getters.selfDeliveryDepartments.find((d) => d.id == departmentId);
        commit("setDeliverySelfDepartment", department);
    },
    async resetDeliveryAddress({ commit, dispatch }) {
        commit("setDeliveryAddress", {});

        this.$postCartQueue.remove("cart/delivery_");
        this.$postCartQueue.remove("cart/deliveryTime");

        try {
            await this.$postCartQueue.add("cart/clearDeliveryAddress", null);
        } catch (err) {
            console.warn("Ошибка сброса адресе доставки");
            console.error(err);
        } finally {
            dispatch("wait/end", "cart.delivery", { root: true });
        }
    },
    findHistoryAddressId({ getters, commit, rootGetters }, payloadAddress) {
        const address = payloadAddress || getters.deliveryAddress;
        const checkFields = ["street", "house", "entrance", "domofon", "floor", "flat"];

        commit("account/setHistoryAddress", null, { root: true });

        if (isAddressEmpty(address)) {
            return;
        }

        if (address && Object.keys(address).length > 0) {
            const foundAddress = (rootGetters["account/addresses"] || []).find((checkAddr) => {
                for (const field of checkFields) {
                    const a = checkAddr[field];
                    const b = address[field];

                    const isSameValue =
                        (!a && !!a == !!b) || // оба значения falsy (пустые или undefined)
                        a == b; // значения одинаковы по значению

                    if (!isSameValue) {
                        return false;
                    }
                }
                return true;
            });

            const addressId = foundAddress && foundAddress.id ? foundAddress.id : "another";
            commit("account/setHistoryAddress", addressId, { root: true });

            if (addressId === "another" && !rootGetters["account/hasAnotherAddress"]) {
                address.id = addressId;
                commit("account/setAnotherAddress", address, { root: true });
            }
        }
    },
    async setDeliveryAddress({ state, commit, dispatch, getters, rootState, rootGetters }, payload) {
        const address = cloneDeep(getters.deliveryAddress);
        const checkFields = ["city", "street", "house", "entrance", "domofon", "floor", "flat", "comment"];
        let isChanged = false;

        for (const field of checkFields) {
            const addressValue = address[field] || "";
            const payloadValue = payload[field] || "";

            if (addressValue != payloadValue) {
                isChanged = true;
                break;
            }
        }

        // зачищаем адрес от старых координат
        address.lat = null;
        address.lon = null;

        const newData = payload || {};
        const fields = [...new Set([...Object.keys(address), ...Object.keys(newData)])];
        for (const fieldName of fields) {
            address[fieldName] = newData[fieldName];
        }

        if (!payload.id) {
            delete address.id;
        }

        const checkEmptyFields = ["comment", "flat", "entrance", "floor", "domofon"];
        checkEmptyFields.forEach((fld) => {
            if (isEmptyString(address[fld])) {
                delete address[fld];
            }
        });

        if (address.comment) {
            commit("setDeliveryAddressComment", address.comment);
        }

        if (isAddressEmpty(address)) {
            return;
        }

        if (isChanged) {
            if (address.id && address.id !== "another") {
                commit("account/setHistoryAddress", address.id, { root: true });
            } else {
                await dispatch("findHistoryAddressId", address);
            }

            const historyAddress = cloneDeep(rootGetters["account/historyAddress"]);

            if (historyAddress) {
                Object.assign(address, historyAddress);
                commit("setDeliveryAddressComment", "");
            }

            if (address.id === "another") {
                commit("account/setAnotherAddress", cloneDeep(address), { root: true });
            }

            const updateNow =
                payload.street !== state.deliveryAddress?.street || payload.house !== state.deliveryAddress?.house;
            commit("setDeliveryAddress", cloneDeep(address));
            commit("setDeliveryAddressAdditional", address);

            if (updateNow) {
                await dispatch("setDelivery", {
                    delivery_type: DELIVERY_TYPE.DELIVERY,
                    delivery_address: address,
                    cart_department_id: payload.cart_department_id,
                });
            }
        }
    },
    changeDeliveryType({ commit, dispatch, getters, state, rootState, rootGetters }, payload) {
        const newType = payload && payload.type ? payload.type : getters.deliveryType;
        payload.isHeader = payload.isHeader || false;

        if (!newType || getters.deliveryType == newType) {
            return;
        }

        commit("cart/setDeliveryType", newType, { root: true });

        const params: any = { delivery_type: newType };

        if (newType == DELIVERY_TYPE.SELF && !getters.emptyDeliverySelf) {
            params.delivery_department_id = getters.deliverySelfDepartemnt.id;
        } else if (newType == DELIVERY_TYPE.HALL && !getters.emptyDeliverySelf) {
            params.delivery_department_id = getters.deliverySelfDepartemnt.id;
        } else if (newType == DELIVERY_TYPE.SELF_CAR && !getters.emptyDeliverySelf) {
            params.delivery_department_id = getters.deliverySelfDepartemnt.id;
            params.delivery_address = { comment: state.deliveryAddressComment };
        } else if (newType == DELIVERY_TYPE.DELIVERY) {
            if (getters.emptyDeliveryAddress && rootGetters["account/hasAnotherAddress"]) {
                commit("setDeliveryAddress", rootState.account.anotherAddress);
            }
            if (!getters.emptyDeliveryAddress) {
                params.delivery_address = getters.deliveryAddress;
            }
        } else {
            params.background = true;
        }

        if (payload.isHeader && newType == DELIVERY_TYPE.HALL && !getters.deliverySelfDepartemnt?.restaurant) {
            commit("setDeliverySelfDepartment", null);
        } else {
            dispatch("setDelivery", params);
        }
    },
    clearTimeType({ state, dispatch, commit, getters }, type) {
        commit("setTimeType", type || DELIVERY_TIME.CURRENT);

        commit("setCurrentDateTime", { date: null, time: null });

        dispatch("errors/clearError", "delivery", { root: true });
        dispatch("errors/clearError", "stop_list", { root: true });
        dispatch("errors/clearError", "time", { root: true });
        commit("setSpecificTimesAvalable", {});
    },
    async setTimeType({ state, dispatch, commit, getters }, payload) {
        if (state.timeType != payload.type || payload.force) {
            dispatch("clearTimeType", payload.type);

            if (state.timeType == DELIVERY_TIME.CURRENT) {
                dispatch("wait/start", "cart.delivery", { root: true });

                try {
                    const resp = await this.$postCartQueue.add(
                        "cart/deliveryTime",
                        {
                            desired_time: null,
                            check_stop: STOP_LIST_MODE_FAIL,
                        },
                        0
                    );
                    commit("setLastUpdateTime");
                    dispatch("checkRemovedProducts", resp);
                    commit("setDeliveryClosestSelf", {
                        payload: resp.cart.delivery_closest_self,
                        noDelivery: false,
                    });
                    dispatch("updateCurrentDateAndTime");
                } catch (error) {
                    dispatch("timeChangeError", error);
                } finally {
                    dispatch("wait/end", "cart.delivery", { root: true });
                }
            }
        }
    },
    async setDeliveryTime({ commit, dispatch, getters, rootGetters, state }, payload: DeliveryTimePayload) {
        const { date, time, type } = payload;

        commit("setCurrentDateTime", { date, time });

        const itType = type || state.timeType;

        if (!date || !time) {
            return;
        }

        const newDeliveryTime = date + "T" + time;

        dispatch("errors/clearError", "delivery", { root: true });
        dispatch("errors/clearError", "stop_list", { root: true });
        dispatch("errors/clearError", "time", { root: true });
        commit("setSpecificTimesAvalable", {});
        dispatch("wait/start", "cart.delivery", { root: true });
        try {
            const resp: any = await this.$postCartQueue.add(
                "cart/deliveryTime",
                {
                    desired_time: itType == DELIVERY_TIME.CURRENT ? null : newDeliveryTime,
                    check_stop: STOP_LIST_MODE_FAIL,
                },
                0
            );

            commit("setLastUpdateTime");
            dispatch("checkRemovedProducts", resp);
            dispatch("updateCurrentDateAndTime");
        } catch (error) {
            dispatch("timeChangeError", error);
        } finally {
            dispatch("wait/end", "cart.delivery", { root: true });
        }
    },
    deliveryChangeError({ commit, dispatch, getters, rootState, rootGetters }, error) {
        dispatch("errors/addError", { ["delivery." + error.error_code]: error.error_message }, { root: true });

        if (error.error_code == "unavailable_address" && error.cart.delivery_closest_self?.length > 0) {
            // Доставка недоступна
            dispatch(
                "modals/openModal",
                {
                    modalName: "NoDelivery",
                    modalData: { selfDeliveryChoice: error.cart.delivery_closest_self },
                },
                { root: true }
            );
        } else {
            dispatch(
                "modals/openModal",
                {
                    modalName: "NotificationModal",
                    modalData: { title: error.error_message || "Неизвестная ошибка установки доставки" },
                },
                { root: true }
            );
        }
    },
    timeChangeError({ commit, dispatch, getters, rootState }, error) {
        if (error.error_code == "stop_list") {
            dispatch("errors/addError", { stop_list: error }, { root: true });
        }
        if (error.error_code == "invalid_address" || error.error_code == "unavailable_address") {
            dispatch("deliveryChangeError", error);
        } else {
            dispatch("errors/addError", { time: error.error_message }, { root: true });
            commit("setSpecificTimesAvalable", {
                date:
                    error.time_back || error.time_after
                        ? moment.parseZone(error.time_back || error.time_after).format(DEFAULT_DATE_FORMAT)
                        : null,
                time_back: error.time_back
                    ? {
                          value: error.time_back,
                          time: moment.parseZone(error.time_back).format("HH:mm"),
                          date: moment.parseZone(error.time_back).format(DEFAULT_DATE_FORMAT),
                      }
                    : null,
                time_after: error.time_after
                    ? {
                          value: error.time_after,
                          time: moment.parseZone(error.time_after).format("HH:mm"),
                          date: moment.parseZone(error.time_after).format(DEFAULT_DATE_FORMAT),
                      }
                    : null,
            });
        }
    },
    async updateDeliveryTime({ state, commit, dispatch, getters }, payload) {
        const isForced = payload && payload.force;
        const needUpdate =
            (!state.lastDeliveryUpdate || moment.duration(moment().diff(state.lastDeliveryUpdate)).asMinutes() > 5) &&
            !this.$postCartQueue.isEmpty();

        if ((needUpdate || isForced) && !getters.emptyDelivery && !state.isUpdateDeliveryTimeInProgress) {
            commit("setUpdateDeliveryTimeInProgress", true);

            try {
                await dispatch("cart/loadCart", true, { root: true });

                commit("setLastDeliveryTimeUpdated");
                commit("setUpdateDeliveryTimeInProgress", false);
            } catch (err) {
                console.warn("Ошибка обновления времени доставки");
                console.error(err);
            }
        }
    },
    validate({ commit, dispatch, getters, rootState, state, rootGetters }) {
        dispatch("errors/clearError", "delivery", { root: true });

        if (!getters.isSelfDelivery && !getters.isDelivery && !getters.isSelfCarDelivery && !getters.isHallDelivery) {
            return Promise.reject({ "delivery.empty": "Выберите доставку/самовывоз " });
        }
        if (getters.isSelfCarDelivery && !state.deliveryAddressComment) {
            return Promise.reject({ "delivery.self_car": "Укажите данные автомобиля" });
        }
        if (getters.isSelfCarDelivery && !getters.deliverySelfDepartemnt) {
            return Promise.reject({ "delivery.self": "Выберите адрес самовывоза" });
        }
        if (getters.isSelfDelivery && !getters.deliverySelfDepartemnt) {
            return Promise.reject({ "delivery.self": "Выберите адрес самовывоза" });
        }
        if (getters.isDelivery && !getters.deliveryAddress) {
            return Promise.reject({ "delivery.delivery": "Выберите адрес доставки" });
        }
        if (
            getters.isDelivery &&
            getters.deliveryAddress &&
            !getters.deliveryAddress.street &&
            !getters.deliveryAddress.house
        ) {
            return Promise.reject({ "delivery.address": "Укажите улицу и номер дома" });
        }
        if (!getters.isDeliveryAddressCorrect) {
            return Promise.reject({ "delivery.address": "Не удалось определить адрес доставки" });
        }
        if (getters.isSpecificTime && !getters.isSpecificTimeSelected) {
            return Promise.reject({ time: "Укажите желаемое время" });
        }

        if (rootGetters["cart/hasStopListProducts"]) {
            return Promise.reject({ "delivery.delivery": "Не все продукты доступны в Вашем заказе" });
        }

        if (rootState.siteId == "eda1" && getters.minDeliveryAmountError) {
            return Promise.reject({ "delivery.delivery": "Дополните заказ" });
        }

        return dispatch("errors/checkErrors", ["delivery", "time"], { root: true });
    },
    checkRemovedProducts({ dispatch }, apiResponse) {
        if (!apiResponse) {
            return;
        }

        if (!apiResponse.success || !apiResponse.products_removed) {
            return;
        }

        const messageLines = ["Следующие товары недоступны в выбранном магазине:"];

        apiResponse.products_removed.forEach((prod) => {
            messageLines.push(`<div class="removed-product-item">${prod.name}</div>`);
        });

        dispatch(
            "errors/addDisplayError",
            {
                errors: {
                    "products.error_modal": {
                        html: messageLines.join(""),
                        cssBody: "modal-removed-products",
                    },
                },
                timeout: 10000,
            },
            { root: true }
        );
    },
    updateCurrentDateAndTime({ state, getters, commit }) {
        commit("setCurrentDateTime", {
            date: getters.deliveryDate,
            time: getters.deliveryTime,
        });
    },
    checkLongCookingGift({ state, dispatch, commit, rootState, rootGetters }) {
        if (!rootGetters["promo/promoPopupDelivery"]) {
            return;
        }

        if (state.isPromoDeliveryShown) {
            return;
        }

        const giftSelected = rootGetters["promo/giftProductsPopupDelivery"].filter(
            (gift) => gift.selected_count
        ).length;
        if (giftSelected) {
            console.log("** gift alredy add");
            return;
        }

        commit("setPromoDeliveryShown", true);
        dispatch(
            "modals/openModal",
            {
                modalName: "PromoPopupDelivery",
                componentProps: { size: "m" },
            },
            { root: true }
        );
    },
    async checkCityViaIp({ state, dispatch, commit, rootState }) {
        try {
            const resp = await fetch(`${config.GEO_HELPER_URL}/geo-ip/city-by-ip`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ ip: rootState.ip }),
            });

            const respData = await resp.json();
            const cityData = respData.data.city;

            let checkCity: any = null;
            if (state.cities) {
                checkCity = state.cities.find((city) => city.name === cityData);
            }
            commit("setCityViaIp", cityData);

            if (state.city?.name && cityData && state.city.name !== cityData) {
                dispatch(
                    "modals/openModal",
                    // @ts-ignore
                    { modalName: "CitySelect", modalData: { cityIp: checkCity ? cityData : state.city.name } },
                    { root: true }
                );
            }
        } catch (err) {
            console.warn("Ошибка проверки города по ip");
            // console.error(err);
        }
    },
    async fetchGeoPosition({ state, commit, dispatch }) {
        return await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    dispatch("modals/closeModal", "CitySelect", { root: true });
                    commit("setMapClickLoading", true);

                    await this.$postCartQueue
                        .add(
                            "geo/address",
                            {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude,
                                city_id: state.city?.id,
                                estimate_price: true,
                            },
                            500
                        )
                        .then((response) => {
                            commit("setCurrentGeoPosition", {
                                ...response,
                                lat: position.coords.latitude,
                                lon: position.coords.longitude,
                                success: true,
                            });
                            resolve({
                                ...response,
                                lat: position.coords.latitude,
                                lon: position.coords.longitude,
                            });
                        })
                        .catch((error) => {
                            commit("setCurrentGeoPosition", {
                                ...error,
                                lat: position.coords.latitude,
                                lon: position.coords.longitude,
                                success: false,
                            });
                            reject(error);
                        })
                        .finally(() => {
                            commit("setMapClickLoading", false);
                        });
                },
                (err) => {
                    if (err.code === 1) {
                        commit("setGeoDenied", true);
                    }

                    reject(err);
                    commit("setMapClickLoading", false);
                }
            );
        });
    },

    cancelFetchGeoPosition({ commit }) {
        this.$postCartQueue.remove("geo/address_");
        commit("setMapClickLoading", false);
    },
};
