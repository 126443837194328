//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from "vuex";
import DefaultLayoutMixin from "../mixins/DefaultLayoutMixin";
import { isIndexPage } from "~/service/catalog-router/helpers";
import CookieAgreement from "~/components/CookieAgreement.vue";

export default {
    mixins: [DefaultLayoutMixin],
    computed: {
        ...mapState("account", ["showMobileAppBanner"]),
        ...mapGetters("delivery", ["showPanelSelectDelivery"]),
        openedBanner() {
            return this.showMobileAppBanner;
        },
        routeIsCatalog() {
            return isIndexPage(this.$router.currentRoute);
        },
    },
    mounted() {
        if (this.$router.currentRoute.fullPath == "/") {
            window.scrollTo(0, 0);
        }
    },
    // beforeDestroy() {
    //     this.$store.commit("toogleNewYearAnimation", true);
    // },
};
