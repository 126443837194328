import { render, staticRenderFns } from "./ModalWrapper.vue?vue&type=template&id=ab60bd42&scoped=true&"
import script from "./ModalWrapper.vue?vue&type=script&lang=js&"
export * from "./ModalWrapper.vue?vue&type=script&lang=js&"
import style0 from "./ModalWrapper.vue?vue&type=style&index=0&id=ab60bd42&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab60bd42",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CrossBig: require('/usr/src/app/components/icons/CrossBig.vue').default})
