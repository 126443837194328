//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import Vk from "~/components/icons/Vk.vue";
import Telegram from "~/components/icons/Telegram.vue";

export default {
    name: "NavSoc",
    components: { Telegram, Vk },
    data() {
        return {};
    },
    computed: {
        ...mapState("delivery", ["city"]),
        vkLink() {
            return this.city.socials.VK;
        },
        telegramLink() {
            return this.city.socials.TELEGRAM;
        },
    },
};
