//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
import AllCustomButtons from "./common/AllCustomButtons.vue";
import localStorageHelper from "~/service/local-storage-helper";

export default {
    computed: {
        // async cookieAccepted() {
        //     const cookie = !!await localStorageHelper.getValue('cookie-agreement-accepted');
        //     console.log(cookie, "checkCookie")
        //     return cookie
        // },
    },
    methods: {
        ...mapActions("account", ["showPersonalDataProcessingAgreement"]),
        acceptCookie() {
            localStorageHelper.setValue("cookie-agreement-accepted", true);
            this.cookieAccepted = true;
        },
    },
    async created() {
        this.cookieAccepted = await localStorageHelper.getValue("cookie-agreement-accepted");
    },
    data() {
        return {
            cookieAccepted: false,
        };
    },
};
