//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from "vuex";
import CrossBig from "~/components/icons/CrossBig.vue";

export default {
    name: "ModalWrapper",
    components: { CrossBig },
    data() {
        return {
            modal: null,
            showPopup: false,
            moveUp: false,
            viewHeight: 0,
            viewHeightInterval: null,
        };
    },
    computed: {
        ...mapState("modals", ["arrOpenedModals"]),
        ...mapGetters("modals", ["defaultLayout", "getOpenedModal"]),
        ...mapGetters("productPopup", { productOpenPopup: "isShow" }),
        modalElementClass() {
            return [
                `modal-element__size_${this.getOpenedModal?.componentProps?.size || "m"}`,
                `${this.getOpenedModal?.componentProps?.no_bg ? "modal-element_no-bg" : ""}`,
            ];
        },
        defaultScroll() {
            return this.$screen.width > 480 && this.getOpenedModal?.componentProps?.defaultScroll !== false
                ? "default-scroll"
                : "";
        },
        overflowHidden() {
            return this.getOpenedModal?.componentProps?.overflowHidden ? "overflowHidden" : "";
        },
        openedModal() {
            return this.getOpenedModal?.modalName;
        },
        isNoAnimate() {
            return this.getOpenedModal?.componentProps?.noAnimate;
        },
    },
    mounted() {
        this.onPopupOpen();
        this.$nuxt.$on("Escape-pressed", this.onPopupClose);
        window.addEventListener("popstate", this.closePopup);

        if (this.$device.isIos) {
            setTimeout(() => {
                this.modal = this.$refs.modal;
                this.fixEvents();
            }, 200);
        }
    },
    beforeDestroy() {
        this.$nuxt.$off("Escape-pressed", this.onPopupClose);
        this.onPopupClose();
    },
    methods: {
        ...mapActions("modals", ["closeModal"]),
        fixEvents() {
            ["touchmove", "scroll"].forEach((event) => {
                this.modal.addEventListener(
                    event,
                    (e) => {
                        e.stopPropagation();
                    },
                    { passive: false }
                );
            });
        },
        onPopupOpen() {
            this.styleModal = document.createElement("style");
            const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

            this.styleModal.innerHTML = `.open-modal-default {padding-right: ${scrollBarWidth}px;overflow: hidden;} .open-modal-default .wrap-top-line {padding-right: ${scrollBarWidth}px;}`;
            document.body.parentNode.insertBefore(this.styleModal, document.body);

            if (document.body.classList.contains("open-modal-default")) {
                document.querySelector(".wrap-top-line").classList.add("move-up");
            }
            document.body.classList.add("open-modal-default");

            this.showPopup = true;
        },
        onPopupClose() {
            const topLine = document.querySelector(".js-wrap-top-line");
            // if have default header
            if (topLine) {
                this.hideDefaultPopup();
                return;
            }
            // if not have header
            this.styleModal.remove();
            document.body.classList.remove("open-modal-default");
            this.showPopup = false;
            clearInterval(this.viewHeightInterval);
            this.closeModal();
        },
        hideDefaultPopup() {
            if (this.arrOpenedModals.length > 1) {
                this.closeModal();
                return;
            }

            const topLine = document.querySelector(".js-wrap-top-line");
            if (topLine.classList.contains("move-up")) {
                document.querySelector(".wrap-top-line").classList.remove("move-up");
            } else {
                this.$refs.modalContainer?.classList.add("close-modal");
                if (!this.productOpenPopup) {
                    document.body.classList.remove("open-modal-default");
                }
            }
            if (this.styleModal) {
                topLine.addEventListener("transitionend", removeBlockStyle);
                const removeBlockStyle = () => {
                    this.styleModal.remove();
                    topLine.removeEventListener("transitionend", removeBlockStyle);
                };
            }
            this.showPopup = false;

            clearInterval(this.viewHeightInterval);
            this.closeModal();
        },
    },
};
