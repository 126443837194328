//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { numberWithSpaces, orderBy } from "~/util/helpers";
import Minus from "~/components/icons/Minus.vue";
import Plus from "~/components/icons/Plus.vue";
import Trash from "~/components/icons/Trash.vue";
import ProductBeforeDiscountPrice from "~/components/products/ProductBeforeDiscountPrice.vue";
import AddressMapIcon from "~/components/icons/AddressMapIcon.vue";
import { sendYandexMetric } from "~/service/ecommerce";

export default {
    name: "ProductActions",
    components: { AddressMapIcon, ProductBeforeDiscountPrice, Trash, Plus, Minus },
    props: {
        location: {
            type: String,
            default: null,
            validator(value) {
                return ["catalog", "cart", "popup", "preview", "search"].includes(value);
            },
        },
        product: { type: Object },
        productCart: { type: Object },
        viewCatalog: { type: String },
        arrCommentIds: { type: Array },
        disabled: { type: Boolean },
        gift: { type: Object },
        modifiers: { type: Array },
        selModifiers: { type: Array },
        showOnlyDelete: { type: Boolean },
        fullModifiersInfo: {
            type: Object,
            default: null,
        },
        recommended: {
            type: Number,
            default: null,
        },
    },
    emits: ["product-added"],
    data() {
        return {
            currentProductModifier: null,
            productIsLoading: false,
        };
    },
    computed: {
        ...mapState("metrika", ["paramsAB"]),
        ...mapState("cart", ["content"]),
        ...mapGetters("delivery", ["deliveryAddress", "deliverySelfDepartemnt"]),
        ...mapGetters("cart", ["productsInCart"]),
        ...mapGetters("products", ["getStopList", "getModifiersErrors"]),

        isShowDefaultBtn() {
            if (this.location !== "popup") {
                return (
                    !this.productCount && this.viewCatalog !== "list" && this.canAddProduct && !this.showOnlyDelete
                );
            }

            return !this.productCount && this.canAddProduct && !this.showOnlyDelete;
        },
        productInCart() {
            if (this.productCart) {
                return this.productCart;
            } else {
                return this.content?.products?.find((prod) => {
                    const cartProdModIds = orderBy(
                        (prod.sel_modifiers || []).map((m) => (typeof m.id === "string" ? parseInt(m.id) : m.id))
                    ).join("_");
                    const payloadModIds = orderBy(
                        (this.sel_modifiers || this.modifiers || []).map((m) =>
                            typeof m.id === "string" ? parseInt(m.id) : m.id
                        )
                    ).join("_");

                    return this.product.id == prod.id && payloadModIds == cartProdModIds;
                });
            }
        },
        inCatalog() {
            return this.location == "catalog";
        },
        inSearch() {
            return this.location == "search";
        },
        inPopup() {
            return this.location == "popup";
        },
        productCount() {
            if (this.gift) {
                return this.gift.selected_count;
            }

            // for product modifiers open in cart
            // if(this.selModifiersQuantity){
            //     return this.selModifiersQuantity
            // }

            if (this.productInCart?.quantity) {
                return this.productInCart.quantity;
            }

            return null;
        },
        price() {
            if (!this.isLoading && this.productInCart) {
                return this.availableProduct.price;
            }

            let modifiersProductPrice = this.availableProduct.price;
            let modConsiderPrice = 0;
            let allModPrice = 0;

            if (this.fullModifiersInfo) {
                this.fullModifiersInfo.modifiers.forEach((mod) => {
                    const getPrice = mod.consider_price || true;
                    if (getPrice) {
                        modConsiderPrice += mod.price;
                    }
                    allModPrice += mod.price;
                });

                modifiersProductPrice = this.fullModifiersInfo.price - allModPrice + modConsiderPrice;
            }

            return this.fullModifiersInfo ? modifiersProductPrice : this.availableProduct.price;
        },
        canAddProduct() {
            if (this.gift) {
                return this.gift.canAdd;
            }
            return true;
        },
        availableProduct() {
            if (this.productInCart) {
                return this.currentProductModifier || this.productInCart;
            }
            return this.currentProductModifier || this.product;
        },
        addressSelected() {
            return (
                (this.deliveryAddress && this.deliveryAddress.street !== undefined) || this.deliverySelfDepartemnt
            );
        },
        isMobile() {
            return this.$screen.width < 1025;
        },
        isLoading() {
            return this.productIsLoading;
        },
        hideButtonInCatalog() {
            return this.disabled && this.inCatalog;
        },
        priceBeforeDiscount() {
            if (this.modifiers && this.modifiers[0]) {
                if (this.modifiers[0]?.price_before_discount) {
                    const priceIngredients = this.price - this.modifiers[0].price;
                    return this.modifiers[0].price_before_discount + priceIngredients;
                }
                return null;
            } else {
                return this.product.price_before_discount;
            }
        },
        isShowDiscount() {
            return this.modifiers ? this.modifiers.length <= 1 : true;
        },
        waitCatalog() {
            return this.inPopup && (this.$wait.is("cart.delivery") || this.$wait.is("catalog"));
        },
    },
    methods: {
        ...mapActions("modals", ["openModal"]),
        ...mapMutations("products", ["setModifiersErrors"]),
        async addProduct() {
            if (this.addressSelected) {
                // this.toggleHeart()
                if (this.gift && this.canAddProduct) {
                    this.$nuxt.$emit(`add-gift-${this.gift.id}`);
                    return;
                }

                const inStop = this.getStopList.filter((p) => p.id === this.availableProduct.id);

                if (inStop && inStop.stops && !inStop.stops[0].date_to) {
                    this.openModal({
                        modalName: "InfoModal",
                        modalData: {
                            text: "Данное блюдо недоступно к заказу",
                            confirmation: true,
                            no: "Понятно",
                            regretHandler: async () => {},
                        },
                    });
                    return;
                }

                if (this.inCatalog || this.inPopup) {
                    sendYandexMetric("Add-to-basket");
                }

                this.setModifier();

                this.validateModifiers();

                if (this.inPopup && !this.getModifiersErrors.length) {
                    this.$emit("product-added");
                }

                if (!this.getModifiersErrors.length) {
                    this.productIsLoading = true;
                    if (this.recommended) {
                        sendYandexMetric("click-on-recommendation-card");
                        await this.$store.dispatch("products/addProduct", {
                            product: this.product,
                            isRecommendation: true,
                            recommended_by_product: this.recommended,
                        });
                    } else {
                        await this.$store.dispatch("products/addProduct", {
                            product: this.availableProduct,
                            arrCommentIds: this.arrCommentIds,
                            isRecommendation: false,
                        });
                    }

                    this.productIsLoading = false;
                    this.currentProductModifier = null;
                    this.setModifiersErrors();
                }
            } else {
                this.openModal({
                    modalName: "DeliveryMap",
                    modalData: { chosenCity: this.selectedCity },
                });
            }
        },
        async removeProduct() {
            if (this.gift) {
                this.$nuxt.$emit(`remove-gift-${this.gift.id}`);
                return;
            }

            this.setModifier();
            this.productIsLoading = true;
            await this.$store.dispatch("products/removeProduct", this.availableProduct);
            this.productIsLoading = false;
            this.currentProductModifier = null;
        },
        formatValue(value) {
            return numberWithSpaces(value);
        },
        validateModifiers() {
            this.setModifiersErrors();
            if (!this.product) {
                return;
            }
            if (this.currentProductModifier) {
                const groups = [...this.product.modifier_groups];
                const groupsWithModifiers = [];

                const withMainMod = this.currentProductModifier.modifiers.find(
                    (currentMod) => currentMod.is_complement === false
                );

                groups.forEach((group) => {
                    let isCheck = true;
                    if (withMainMod) {
                        isCheck = this.fullModifiersInfo.modifiersKey.includes(group.condition_product_id);
                    }
                    // Добавляем модификаторы в группу:
                    if (isCheck) {
                        const selMod = this.currentProductModifier.modifiers.filter(
                            (modifier) => modifier.group_id === group.id
                        );

                        group.modifiers = selMod;
                        groupsWithModifiers.push(group);
                    }
                });

                const errorsModifiers = [];

                groupsWithModifiers.forEach((group) => {
                    const countMod = group.modifiers.reduce((acc, obj) => {
                        return acc + obj.quantity;
                    }, 0);
                    if (group.assemble_yourself_combo && countMod < group.min_modifiers_count) {
                        errorsModifiers.push(group.id);
                    }
                });

                this.setModifiersErrors(errorsModifiers);
            }
        },
        setModifier() {
            if (this.productInCart?.sel_modifiers) {
                // copy object because - not to change vuex outside store
                this.currentProductModifier = Object.assign({}, this.productInCart);
                this.currentProductModifier.modifiers = this.currentProductModifier?.sel_modifiers.map((item) => {
                    return {
                        id: item.id,
                        quantity: 1,
                        group_id: item.group_id,
                    };
                });
                return;
            }

            if (this.product && this.modifiers) {
                // copy object because - not to change vuex outside store
                this.currentProductModifier = Object.assign({}, this.product);
                this.currentProductModifier.modifiers = this.modifiers.map((item) => {
                    return {
                        id: item.id,
                        quantity: item.quantity,
                        group_id: item.group_id,
                        price: item.price,
                        is_complement: item.is_complement,
                    };
                });
            }
        },
    },
};
