/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-undef */
import $ from "jquery";
import { getContext } from "./context-keeper/index";
import { logEcommerce } from "./client-logger";

export let sendYandexMetric = (action, params, method) => {};
export let EcommerceYandex = {};
export let sendVKMetric = (action, params) => {};

(() => {
    sendYandexMetric = function (action, params, method) {
        if (method === "userParams") {
            EcommerceYandex.userParams(params);
        } else {
            EcommerceYandex.reachGoal(action, params);
        }
    };

    const getCurrentCityId = async () => {
        const ctx = await getContext();
        return ctx?.store?.state?.delivery?.city?.id;
    };

    const EcommerceProductHelper = (function () {
        function getData($product, quantity, variant, ga) {
            if ($.isPlainObject($product)) {
                return processResult($product, quantity, variant, ga);
            } else if ($.type($product) == "string") {
                $product = $($product);
            }

            const data = {
                id: $product.data("id"),
                name: $product.data("name"),
                price: $product.data("price"),
                brand: $product.data("brand"),
                category: $product.data("category"),
            };

            variant = variant || $product.data("variant");
            quantity = Number(quantity || $product.data("quantity"));

            return processResult(data, quantity, variant, ga);
        }

        function processResult(data, quantity, variant, ga) {
            const newData = Object.assign({}, data);
            if (ga) {
                newData.category = newData.brand + "/" + newData.category;
                return newData;
            }

            // поменяем местами бренд и категорию
            const brand = newData.brand;
            newData.brand = newData.category;
            newData.category = brand;
            if (variant) {
                newData.variant = variant;
            }
            if (quantity) {
                newData.quantity = Math.abs(quantity);
            }
            return newData;
        }

        return {
            getProductData(product, quantity, variant, ga) {
                return getData(product, quantity, variant, ga);
            },
        };
    })();

    const Ecommerce = (function () {
        return {
            enterProduct(product) {
                EcommerceYandex.enterProduct(product);
                EcommerceGA.enterProduct(product);
            },
            enterCategory(url) {
                EcommerceGA.enterCategory(url);
            },
            addProduct(product, cnt, variant) {
                EcommerceYandex.addProduct(product, cnt, variant);
                EcommerceGA.addProduct(product, cnt, variant);
                EcommerceVK.addProduct(product, cnt, variant);
                EcommerceFacebook.addProduct(product, cnt, variant);
            },
            orderStepProducts(products) {
                EcommerceYandex.reachGoal("go-to-basket");
                EcommerceGA.order("order-products", products);
                EcommerceVK.goal("initiate_checkout", products);
                EcommerceFacebook.order("InitiateCheckout", products);
            },
            orderStepDetails(products) {
                EcommerceYandex.reachGoal("choice_delivery");
                EcommerceGA.order("order-details", products);
            },
            orderStepPayment(products) {
                EcommerceYandex.reachGoal("choice_payment");
                EcommerceGA.order("order-payment", products);
                EcommerceVK.order("add_payment_info", products);
                EcommerceFacebook.order("AddPaymentInfo", products);
            },
            orderStepFinish(products, orderData, coupon) {
                logEcommerce("Ecommerce.orderStepFinish:begin", orderData.id, {
                    productsCount: (products || []).length,
                    coupon,
                });
                const totalSumm = parseFloat(orderData.total_amount_clean);
                EcommerceYandex.order(products, orderData.id, coupon);
                EcommerceGA.order("finish", products, orderData.id, coupon);
                EcommerceVK.order("purchase", products, totalSumm);
                EcommerceFacebook.order("Purchase", products, totalSumm);
            },
            callback(phone) {
                EcommerceGA.callback(phone);
                EcommerceVK.goal("contact");
                EcommerceFacebook.goal("Contact");
            },
            promo(banner_url) {
                EcommerceGA.promo(banner_url);
            },
            trackPageview(url) {
                // EcommerceYandex.trackPageview(url);
                EcommerceGA.trackPageview(url);
                EcommerceVK.trackPageview(url);
                EcommerceFacebook.trackPageview(url);
            },
            registerComplete() {
                EcommerceVK.goal("complete_registration");
                EcommerceFacebook.goal("CompleteRegistration");
            },
        };
    })();

    const EcommerceFacebook = (function () {
        function sendRequest(methodName, data) {
            if (process.client && window && window.fbq) {
                const method = {};
                method[methodName] = data;

                try {
                    console.log("fb track", methodName, data);
                    fbq("track", methodName, data);
                } catch (e) {
                    console.warn("EcommerceFacebook");
                    console.error(e);
                }
            }
        }

        function getProducts(products) {
            return (products || []).map((prod) => {
                const prodData = EcommerceProductHelper.getProductData(prod);
                return prodData.id;
            });
        }

        return {
            enterProduct(product) {},
            addProduct(product, cnt, variant) {
                cnt = parseInt(cnt);
                if (cnt == 0 || isNaN(cnt)) {
                    return;
                }

                sendRequest("AddToCart", {
                    content_ids: getProducts([product]),
                    value: cnt,
                });
            },
            order(step, products, totalSumm) {
                const params = { content_ids: getProducts(products) };
                if (totalSumm) {
                    params.value = totalSumm;
                }
                sendRequest(step, params);
            },
            trackPageview(url) {
                sendRequest("PageView");
            },
            goal(action, params) {
                sendRequest(action);
            },
            conversion(params) {},
        };
    })();

    const EcommerceVK = (function () {
        function sendRequest(methodName, data) {
            if (process.client && window.VK) {
                const method = {};
                method[methodName] = data;

                try {
                    VK.Goal(methodName, data);
                } catch (error) {
                    console.warn("Ошибка запроса Ecommerce VK");
                    console.error(error);
                }
            }
        }

        function getProducts(products) {
            return (products || []).map((prod) => {
                const prodData = EcommerceProductHelper.getProductData(prod);
                return {
                    id: prodData.id,
                    price: prodData.price,
                };
            });
        }

        return {
            enterProduct(product) {
                sendRequest("detail", { products: [EcommerceProductHelper.getProductData(product)] });
            },
            addProduct(product, cnt, variant) {
                cnt = parseInt(cnt);
                if (cnt == 0 || isNaN(cnt)) {
                    return;
                }

                const action = cnt > 0 ? "add_to_cart" : "remove_from_cart";

                sendRequest(action, { products: getProducts([product]) });
            },
            order(step, products, totalSumm) {
                const params = { products: getProducts(products) };
                if (totalSumm) {
                    params.total_price = totalSumm;
                }
                sendRequest(step, params);
            },
            trackPageview(url) {
                sendRequest("page_view");
            },
            goal(action, params) {
                sendRequest(action);
            },
            conversion(params) {
                sendRequest("conversion");
            },
        };
    })();

    sendVKMetric = function (action, params) {
        EcommerceVK.goal(action, params);
    };

    EcommerceYandex = (function () {
        function sendRequest(methodName, data, logData = null) {
            const method = {};
            method[methodName] = data;

            // console.log('EcommerceYandex_' + methodName + '_' + CURRENT_CITY, {data: data, enabled: (window['dataLayer'] ? '1' : '0')} );
            if (methodName === "purchase" && logData && !!logData.orderId) {
                logEcommerce("EcommerceYandex.order:sendRequest_purchase", logData.orderId, {
                    method,
                    dataLayerExists: !!window.dataLayer,
                });
            }

            if (window.dataLayer) {
                dataLayer.push({ ecommerce: method });
            }
        }

        return {
            enterProduct(product) {
                sendRequest("detail", { products: [EcommerceProductHelper.getProductData(product)] });
            },
            addProduct(product, cnt, variant) {
                cnt = parseInt(cnt);
                if (cnt == 0 || isNaN(cnt)) {
                    return;
                }

                const action = cnt > 0 ? "add" : "remove";
                if (action == "add") {
                    EcommerceYandex.reachGoal("Add-to-basket");
                }

                sendRequest(action, { products: [EcommerceProductHelper.getProductData(product, cnt, variant)] });
            },
            order(products, orderId, coupon) {
                logEcommerce("EcommerceYandex.order:begin", orderId);
                const productsData = [];
                for (const k in products) {
                    const productData = EcommerceProductHelper.getProductData(products[k]);
                    if (coupon) {
                        productData.coupon = coupon;
                    }
                    productsData.push(productData);
                }
                const actionField = { id: orderId };
                if (coupon) {
                    actionField.coupon = coupon;
                }
                sendRequest(
                    "purchase",
                    {
                        actionField,
                        products: productsData,
                    },
                    { orderId }
                );
            },
            // trackPageview(url) {
            //     try {
            //         console.log({ ...window.g_Counter }, "check");

            //         if (typeof window.g_Counter === "function") {
            //             window.g_Counter("hit", url);
            //         }
            //         if (typeof window.g_Counter_common === "function") {
            //             window.g_Counter_common("hit", url);
            //         }
            //     } catch (e) {
            //         console.warn("trackPageview");
            //         console.error(e);
            //     }
            // },
            reachGoal(action, params) {
                // console.log("* reaching goal", action, params);
                try {
                    if (window.ymMetrikaCity) {
                        params
                            ? ym(window.ymMetrikaCity, "reachGoal", action, params)
                            : ym(window.ymMetrikaCity, "reachGoal", action);
                    }
                    if (window.ymMetrikaCommon) {
                        params
                            ? ym(window.ymMetrikaCommon, "reachGoal", action, params)
                            : ym(window.ymMetrikaCommon, "reachGoal", action);
                    }
                } catch (error) {
                    console.warn("Ошибка по достижению цели");
                    console.error(error);
                }
            },
            userParams(params) {
                try {
                    if (window.ymMetrikaCity) {
                        ym(window.ymMetrikaCity, "userParams", params);
                    }
                    if (window.ymMetrikaCommon) {
                        ym(window.ymMetrikaCommon, "userParams", params);
                    }
                } catch (error) {
                    console.warn("Ошибка установки параметров пользователя");
                    console.error(error);
                }
            },
            async yaMetricaData(target) {
                return {
                    client_id: await EcommerceYandex.getClientId(), // client_id  из яндекс метрики
                    target, // название цели  в яндекс метрике
                    counter: window.ymMetrikaCity, // идентификатор  счетчика  яндекс метрики
                };
            },
            getClientId() {
                return new Promise((resolve, reject) => {
                    ym(window.ymMetrikaCity, "getClientID", function (clientId) {
                        if (clientId) {
                            resolve(clientId);
                        } else {
                            reject(new Error("Failed to get client ID"));
                        }
                    });
                });
            },
        };
    })();

    function _ga() {
        if (window.gtag) {
            window.gtag.apply(window.gtag, arguments);
        } else if (window.ga) {
            window.ga.apply(window.ga, arguments);
        } else {
            console.log(arguments);
        }
    }

    const EcommerceGoogleAnalitycs = function () {
        return {
            enterProduct(product) {
                const productData = EcommerceProductHelper.getProductData(product, false, false, true);
                productData.position = Math.floor(Math.random() * 11);
                _ga("ec:addProduct", productData);
                _ga("ec:setAction", "detail");
                _ga("send", "pageview", "/virtual/product");
                _ga("send", "event", "product", "view");
            },
            enterCategory() {
                const category = $("#result").data("category");
                $(".product-block").each(function (index, elem) {
                    // eslint-disable-next-line no-undef
                    const id = fromClass(this, "js-prod-data-");
                    const $mod_checked = $(`[id ^= "modifications_list_${id}"]:checked`);
                    const productData = EcommerceProductHelper.getProductData(
                        $(elem),
                        1,
                        $("#modification_title_" + id + "_" + $mod_checked.val()).html(),
                        true
                    );
                    productData.category = productData.brand + "/" + category;
                    productData.list = "Catalog list";
                    productData.type = "view";
                    const key = index + 1;
                    productData.position = key;

                    _ga("ec:addImpression", productData);
                });

                _ga("send", "pageview");
            },
            addProduct(product, cnt, variant) {
                cnt = parseInt(cnt);
                if (cnt == 0 || isNaN(cnt)) {
                    return;
                }
                const productData = EcommerceProductHelper.getProductData(product, cnt, variant, true);
                productData.position = Math.floor(Math.random() * 11);
                _ga("ec:addProduct", productData);
                _ga("ec:setAction", cnt > 0 ? "add" : "remove");
                _ga("send", "pageview", "/virtual/addtocart"); // для воронки
            },
            beginPrepare(products) {
                for (const k in products) {
                    const productData = EcommerceProductHelper.getProductData(products[k], false, false, true);
                    productData.position = k + 1;
                    _ga("ec:addProduct", productData);
                }
                _ga("send", "event", "cart", "step3");
                _ga("send", "pageview", "/virtual/card-step3");
            },
            callback(phone) {
                _ga("ec:addPromo", {
                    id: "call_back",
                    name: "Обратный звонок",
                    creative: "Телефон: " + phone,
                    position: "Обратный звонок",
                });
                _ga("ec:setAction", "promo_click");
                _ga("send", "event", "Обратный звонок", "click", "Обратный звонок");
            },
            promo(banner_url) {
                _ga("ec:addPromo", {
                    id: "banner_click",
                    name: "Переход по баннеру",
                    creative: "banner_link: " + banner_url,
                    position: "main_page",
                });
                _ga("ec:setAction", "promo_click");
                _ga("send", "event", "Переход по баннеру", "click", "banner_link: " + banner_url);
            },
            async order(step, products, orderId) {
                if (products) {
                    for (const k in products) {
                        const productData = EcommerceProductHelper.getProductData(products[k], false, false, true);
                        productData.position = k + 1;
                        _ga("ec:addProduct", productData);
                    }
                }
                if (step == "order-products") {
                    _ga("ec:setAction", "checkout", { step: 1 });
                    _ga("send", "event", "cart", "step1");
                    _ga("send", "pageview", "/virtual/card-step1");
                }

                if (step == "order-details") {
                    _ga("ec:setAction", "checkout", { step: 2 });
                    _ga("send", "event", "cart", "step2");
                    _ga("send", "pageview", "/virtual/card-step2");
                }
                if (step == "order-payment") {
                    _ga("ec:setAction", "checkout", { step: 3 });
                    _ga("send", "event", "cart", "step3");
                    _ga("send", "pageview", "/virtual/card-step3");
                }

                if (step == "finish") {
                    _ga("ec:setAction", "purchase", { id: orderId }); // / идентификатор заказа

                    _ga("send", "event", "cart", "purchase");

                    const cityId = await getCurrentCityId();
                    if (cityId != 1) {
                        _ga("send", "pageview", "/virtual/purchase");
                    }
                }
            },
            trackPageview(url) {
                _ga("send", "pageview", url);
            },
        };
    };

    const EcommerceGTAG = function () {
        return {
            enterProduct(product) {
                const productData = EcommerceProductHelper.getProductData(product, false, false, true);
                productData.position = Math.floor(Math.random() * 11);

                this.trackPageview("/virtual/product");

                _ga("event", "view_item", { items: [productData] });
                _ga("event", "select_content", {
                    content_type: "product",
                    items: [productData],
                });
            },
            enterCategory(url) {
                /* var category = $('#result').data('category');
                $('.product-block').each(function(index, elem){
                    var id = fromClass(this, "js-prod-data-");
                    var $mod_checked = $('[id ^= "modifications_list_' + id + '"]:checked');
                    var productData = EcommerceProductHelper.getProductData($(elem), 1, $("#modification_title_" + id + "_" + $mod_checked.val()).html(), true);
                    productData['category'] = productData['brand']+'/'+category;
                    productData['list'] = 'Catalog list';
                    productData['type'] = 'view';
                    var key = index + 1;
                    productData['position'] = key;

                    _ga('ec:addImpression', productData);
                }); */

                console.log("enterCategory", url);
                this.trackPageview(url);
            },
            addProduct(product, cnt, variant) {
                cnt = parseInt(cnt);
                if (cnt == 0 || isNaN(cnt)) {
                    return;
                }
                const productData = EcommerceProductHelper.getProductData(product, cnt, variant, true);

                this.trackPageview("/virtual/addtocart");

                _ga("event", cnt > 0 ? "add_to_cart" : "remove_from_cart", { items: [productData] });
            },
            beginPrepare(products) {
                this.order("order-payment", products);
            },
            callback(phone) {
                _ga("event", "select_content", {
                    promotions: [
                        {
                            id: "call_back",
                            name: "Обратный звонок",
                            creative_name: "Телефон: " + phone,
                            creative_slot: "Обратный звонок",
                        },
                    ],
                });

                _ga("event", "click", {
                    send_to: this.getGtagId(),
                    event_category: "Обратный звонок",
                    event_label: "Обратный звонок",
                });
            },
            promo(banner_url) {
                _ga("event", "select_content", {
                    promotions: [
                        {
                            id: "banner_click",
                            name: "Переход по баннеру",
                            creative_name: "banner_link: " + banner_url,
                            creative_slot: "main_page",
                        },
                    ],
                });

                _ga("event", "click", {
                    send_to: this.getGtagId(),
                    event_category: "Переход по баннеру",
                    event_label: "banner_link: " + banner_url,
                });
            },
            async order(step, products, orderId, coupon) {
                const items = this.getItems(products);

                if (step == "order-products") {
                    this.trackPageview("/virtual/card-step1");
                    _ga("event", "begin_checkout", { items });
                }

                if (step == "order-details") {
                    this.trackPageview("/virtual/card-step2");
                    _ga("event", "checkout_progress", {
                        checkout_step: 2,
                        items,
                    });
                }
                if (step == "order-payment") {
                    this.trackPageview("/virtual/card-step3");
                    _ga("event", "checkout_progress", {
                        checkout_step: 3,
                        items,
                    });
                }

                const cityId = await getCurrentCityId();
                if (step == "finish" && cityId != 1) {
                    this.trackPageview("/virtual/purchase");
                    _ga("event", "purchase", {
                        transaction_id: orderId,
                        items,
                        coupon: coupon || "",
                    });
                }
            },
            getItems(products) {
                const items = [];
                if (products) {
                    for (const k in products) {
                        const productData = EcommerceProductHelper.getProductData(products[k], false, false, true);
                        productData.list_position = k + 1;
                        items.push(productData);
                    }
                }

                return items;
            },
            trackPageview(url) {
                if (url) {
                    this.setPage(url);
                }
                _ga("event", "page_view");
            },
            setPage(url) {
                _ga("config", this.getGtagId(), { page_path: url });
            },
            getGtagId() {
                return window.GTAG_ID || 0;
            },
        };
    };

    let EcommerceGA = EcommerceGoogleAnalitycs();

    if (process.client && window) {
        // window.$ = $;

        window.dataLayer = window.dataLayer || [];
        window.sendYandexMetric = sendYandexMetric;
        window.Ecommerce = Ecommerce;

        if (window.gtag) {
            EcommerceGA = EcommerceGTAG();
        } else {
            document.addEventListener("onGtagLoaded", () => {
                EcommerceGA = EcommerceGTAG();
            });
        }
    }
})();

export function canUseEcommerce(key = "Ecommerce") {
    if (!process.client) {
        return false;
    }
    return !!window && !!window[key];
}
