// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/cookie.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cookie-agreement{width:100%;max-width:480px;background-color:var(--main-monochrome);border-radius:14px;position:fixed;bottom:20px;right:20px;padding:16px;z-index:5;color:#fff;overflow:hidden;border:1px solid #3c3a3c}@media(max-width: 520px){.cookie-agreement{flex-direction:column;width:calc(100% - 32px);left:50%;transform:translateX(-50%)}}.cookie-agreement::before{content:\"\";position:absolute;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:46px;height:46px;background-repeat:no-repeat;left:12px;top:-2px}.cookie-agreement::after{content:\"\";position:absolute;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:46px;height:46px;background-repeat:no-repeat;right:136px;bottom:-2px;transform:rotate(-96deg)}.cookie-agreement__text{font-family:var(--font-regular)}.cookie-agreement__content{position:relative;z-index:5;display:flex;align-items:center}@media(max-width: 520px){.cookie-agreement__content{flex-direction:column}}.cookie-agreement__link{color:var(--main-red);text-decoration:unset}.cookie-agreement .cookie-agreement__btn{margin-left:8px;width:62px;flex-shrink:0;height:40px}@media(max-width: 520px){.cookie-agreement .cookie-agreement__btn{width:100%;margin-top:12px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
