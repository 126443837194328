import moment from "moment";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { ACCOUNT_TABS } from "@/util/types";
import { dispatchQueue } from "../util/queue";
import cityLinksHelper from "../util/city-links-helper";
import { DELIVERY_TYPE } from "../util/types";
import { ORDER_STEPS, getNextStep, getPrevStep, validateStep } from "~/util/types";
import { OrderState } from "~/store_types/order.types";
import { RootState } from "~/store_types/index.types";

import { PAYMENT_IFRAME_QUERY_KEY } from "~/store_types/payment.types";
import { DEFAULT_COOKIES_OPTIONS } from "~/service/cookies-helper";

const root = { root: true }; // Чтоб не прописывать каждый раз {root: true}

const stepPaths = {
    [ORDER_STEPS.ORDER]: "/order/products",
    [ORDER_STEPS.DETAILS]: "/order/details",
    [ORDER_STEPS.FINISH]: "/order/finish",
};

export const state: () => OrderState = () => ({
    // topСartOpened: false,
    // lastPageUrl: null,
    step: ORDER_STEPS.ORDER,
    currentPhone: null,
    lastOrder: null,
    timeLeft: 0,
    timerStarted: false,
    repeatSeconds: 60, // Интервал отправки сообщения
    needAdditionalProducts: {
        japan: true,
        pizza: true,
        sticks: true,
    },
    newCityPromo: null,
});

export const getters: GetterTree<OrderState, RootState> = {
    // lastPageUrl: (state) => {
    //     return state.lastPageUrl || GLOBAL_BASE_URL;
    // },
    currentOrder: (state, getters, rootState, rootGetters) => {
        const currenOrder = { ...(state.lastOrder || { empty: true }) };
        if (!currenOrder.empty) {
            currenOrder.date = moment.parseZone(currenOrder.delivery_time).format("DD.MM.YYYY");
            currenOrder.time = moment.parseZone(currenOrder.delivery_time).format("HH:mm");

            const leftTime = moment(currenOrder.delivery_time).diff(moment()) / 1000;
            currenOrder.left_time_seconds = leftTime;
            currenOrder.left_time = {
                days: Math.floor(leftTime / (24 * 3600)),
                hours: Math.floor((leftTime / 3600) % 24),
                minutes: Math.floor((leftTime / 60) % 60),
            };
        }
        return currenOrder;
    },
    existCurrentOrder: (state, getters, rootState, rootGetters) => {
        return rootGetters["cart/emptyCart"] && rootGetters["cart/currentOrderCode"];
    },
    // needSmsAuth: (state, getters, rootState, rootGetters) => {
    //     return state.step == ORDER_STEPS.PAYMENT
    //         && rootGetters['delivery/isExternalDelivery']
    //         && !rootGetters['form/isLogged'];
    // },
};

export const mutations: MutationTree<OrderState> = {
    setStep(state, step) {
        step = validateStep(step);
        if (step != state.step) {
            state.step = step;
        }
    },
    setLastOrder(state, order) {
        state.lastOrder = order;
    },
    setCurrentPhone(state, phone) {
        state.currentPhone = phone;
    },
    passTime(state, counter = 100) {
        state.timeLeft -= counter;
        if (state.timeLeft <= 0) {
            state.timerStarted = false;
        }
    },
    setTime(state, setTime) {
        state.timeLeft = setTime;
        if (setTime > 0) {
            state.timerStarted = true;
        }
    },
    toggleAdditionalProducts(state, kitchenType: string) {
        console.log(kitchenType);
        state.needAdditionalProducts[kitchenType] = !state.needAdditionalProducts[kitchenType];
    },
    // setLastPageUrl(state, url) {
    //     state.lastPageUrl = url;
    // },
    // setTopСartOpened(state, val) {
    //     state.topСartOpened = val;
    // },
    setNewCityPromo(state, payload) {
        state.newCityPromo = payload;
        console.log(payload, "payload");
    },
};

export const actions: ActionTree<OrderState, RootState> = {
    decideStepByRoute({ state, commit }) {
        const currentPath = cityLinksHelper.cleanCityPath(this.$router.currentRoute.path);

        let foundStep: string | null = null;
        for (const step in stepPaths) {
            const path = stepPaths[step];
            if (currentPath == path) {
                foundStep = step;
                break;
            }
        }

        commit("setStep", foundStep);

        // Если текущий путь не соответствует найденному этапу заказа, перенаправим на путь найденного этапа
        if (currentPath != stepPaths[state.step]) {
            this.$router.replace(stepPaths[state.step]);
        }
    },
    // init({commit, dispatch, state, rootState}) {

    //     console.log("orders init ", rootState.route.query, state.step);
    //     if(rootState.route.query['success'] !== undefined) {
    //         let success = parseInt(rootState.route.query['success']) == 1;
    //         let orderCode = rootState.route.query['order'];

    //         console.log("completeOrder ", success, orderCode);
    //         if(success && orderCode) {
    //             dispatch("cart/completeOrder", orderCode, {root: true});
    //         }else if(!success) {
    //             dispatch("errors/addError", {'cart.online': "Ошибка онлайн оплаты"}, {root: true});
    //         }
    //     }

    //     dispatch("gotoStep", state.step)
    // },
    resetStep({ commit, dispatch }) {
        commit("form/setChanged", true, root);
        dispatch("cart/clearPaymentTokens", null, root);
        this.$router.push(stepPaths[ORDER_STEPS.ORDER]);
    },
    gotoStep({ commit, getters, rootGetters }, step) {
        /* if(getters.existCurrentOrder) {
            commit("setStep", ORDER_STEPS.FINISH)
        }else if(rootGetters['cart/emptyCart']){
            commit("setStep", ORDER_STEPS.ORDER)
        }else {
            commit("setStep", step);
        } */

        // commit("setStep", step);
        this.$router.push(stepPaths[step] || ORDER_STEPS.ORDER);
    },
    async prevStep({ commit, dispatch, state }) {
        const prevStep = getPrevStep(state.step);
        await this.$router.push(stepPaths[prevStep] || { name: "index" });
    },
    async nextStep({ commit, dispatch, state, rootState, rootGetters }): Promise<null | void> {
        const nextStep: string | undefined = getNextStep(state.step);

        if (!nextStep) {
            return null;
        }

        dispatch("wait/start", "order.next_step", root);
        dispatch("wait/start", "step.check", root);

        try {
            if (!rootState.isOnline) {
                dispatch("showModalOnOffline", null, root);
                return null;
            }

            await dispatch("check", state.step);

            // Переход на страницу TabFinish нужно обработать с оформлением заказа
            if (nextStep === ORDER_STEPS.FINISH) {
                console.log("Переход на страницу TabFinish нужно обработать с оформлением заказа");
                await dispatch("cart/send", null, { root: true });
                return;
            }

            console.log("default next step order", state.step, nextStep);

            commit("setStep", nextStep);
            await dispatch("afterNextStep", nextStep);

            if (this.$router) {
                await this.$router.push(stepPaths[nextStep]);
            } else {
                console.warn("Маршрутизатор недоступен на следующем шаге");
                console.error("router ->", this.$router);
            }
        } catch (error: any) {
            dispatch("errors/addError", error, root);
            if (error && typeof error === "object" && "payment.type" in error) {
                dispatch(
                    "modals/openModal",
                    {
                        modalName: "NotificationModal",
                        modalData: { title: error["payment.type"] },
                    },
                    root
                );
            }
            // dispatch('errors/autoHideError', {key: Object.keys(error)[0]}, {root: true});
        } finally {
            dispatch("wait/end", "order.next_step", root);
            dispatch("wait/end", "step.check", root);
        }
    },
    afterNextStep({ commit, dispatch }, step) {
        switch (step) {
            case ORDER_STEPS.DETAILS:
                dispatch("payment/enter", null, root);
                break;
        }
    },
    async makeDonate({ commit, dispatch }, payload) {
        try {
            const resp = await this.$api.post("/orders/donate", payload);
            await dispatch("afterMakeDonate", resp.data);
        } catch (err) {
            // обработать ошибку доната
        }
    },
    async afterMakeDonate({ commit, dispatch }, resp) {
        try {
            if (resp.qr_url) {
                commit("payment/setQrId", resp.qr_id, root);
                commit("payment/setQrUrl", resp.qr_url, root);

                await dispatch(
                    "modals/openModal",
                    {
                        modalName: "PaymentQrModal",
                        modalData: { cancelRoute: true, isFond: true },
                        componentProps: { size: "xl" },
                    },
                    { root: true }
                );

                if (this.$screen.width! <= 768) {
                    window?.open(resp.qr_url, "_blank")?.focus();
                }

                return Promise.reject({
                    error_code: "need_pay",
                    error_message: "Необходимо заплатить",
                });
            }

            if (resp.payment_url) {
                commit("cart/setOnlineProcessingFlag", true, root);
                commit("payment/setPaymentUrl", resp.payment_url, root);
                this.$router.push(`${this.$router.currentRoute.path}?${PAYMENT_IFRAME_QUERY_KEY}`);
                return Promise.reject({
                    error_code: "need_pay",
                    error_message: "Необходимо заплатить",
                });
            }
        } catch (err) {
            console.warn("Ошибка после доната");
            console.error(err);
            return Promise.reject(err);
        }
    },
    async check({ commit, dispatch, state, rootState, rootGetters }, step) {
        if (!rootState.siteId) {
            return Promise.reject("No siteId stated!");
        }

        try {
            if (ORDER_STEPS.ORDER == step) {
                await dispatch("form/validate", null, { root: true });
                await dispatch("products/validate", null, { root: true });
                await dispatch("promo/validate", null, { root: true });
                await dispatch("cart/validate", null, { root: true });
            }

            if (ORDER_STEPS.DETAILS == step) {
                if (
                    rootGetters["delivery/isDelivery"] &&
                    rootGetters["delivery/deliveryAddress"] &&
                    !rootGetters["delivery/deliveryAddress"].flat
                ) {
                    return Promise.reject({ "delivery.flat": "Укажите номер квартиры" });
                }

                if (rootGetters["delivery/isDelivery"]) {
                    await dispatch(
                        "delivery/setDelivery",
                        {
                            delivery_type: DELIVERY_TYPE.DELIVERY,
                            delivery_address: rootGetters.deliveryAddress,
                            cart_department_id: rootGetters["cart/cartDepartmentId"],
                            is_preserve_delivery_time: true,
                            force: true,
                        },
                        { root: true }
                    );
                }
                await dispatch("delivery/validate", null, { root: true });
                await dispatch("payment/validate", null, { root: true });
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async fetchCurrentOrder({ commit, dispatch, state, rootState, rootGetters }, payload) {
        if (state.lastOrder) {
            return;
        }

        try {
            console.log(payload, "checkPayload");
            const res = await this.$api.get("/orders/current", {
                code: payload?.code ? payload.code : rootGetters["cart/currentOrderCode"],
                phone: payload?.phone ? payload.phone : rootGetters["form/getPhone"],
            });

            commit("setLastOrder", res);
            dispatch("cart/sendEcommerce", res, root);
        } catch (err) {
            // alert('Ошибка получения текущего заказа')
            commit("setLastOrder", null);
            dispatch("resetStep");
        }
    },
    async cancelOrder({ state }, payload) {
        return await this.$api.post("/orders/cancel", {
            reason: payload.reason,
            order_id: payload.orderId,
            cancel_confirm: !!payload.confirmed,
        });
    },
    async sendBetOfferData({ commit, dispatch, rootGetters }, payload) {
        try {
            const resp: any = await this.$api.post("orders/saveBet", payload);

            if (!resp.success) {
                throw new Error(resp.error_message);
            }

            this.$cookies.set(
                `bet_is_placed_${rootGetters["account/findBetOfferOrder"].id}`,
                true,
                DEFAULT_COOKIES_OPTIONS
            );

            dispatch(
                "toasts/addToast",
                {
                    message: "Приняли ставку. В случае выигрыша начислим бонусы в течение 5 дней",
                    type: "success",
                    timeout: 5000,
                },
                root
            );
            commit("modals/removeModal", "BetOffer", root);
            commit("account/deleteBetOffer", payload.order_id, root);
        } catch (error) {
            console.warn("Ошибка сохранения ставки");
            console.error(error);
            dispatch(
                "modals/openModal",
                {
                    modalName: "NotificationModal",
                    modalData: { title: "Не удалось сохранить ставку. Пожалуйста, попробуйте ещё" },
                },
                root
            );
        }
    },
    async newCityPromo({ commit }, payload) {
        const resp = await this.$api.post("/orders/launchCityOrder", payload).then((resp) => {
            if (resp.success) {
                commit("setNewCityPromo", payload);
                return resp;
            }
        });

        return resp;
    },
};
