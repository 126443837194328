import Vue from "vue";
import axios from "@nuxtjs/axios"; // Нужен для вызова this.$axios, иначе TypeScript не понимает
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { Route } from "vue-router";
import { Context } from "@nuxt/types/app";
import { getEcommerceProductData, orderBy } from "../util/helpers";
import { UrlsStorage } from "../service/storages/urls-storage/index";
import { LOCAL_STORAGE_ADDITIONAL_PRODUCTS_KEY, ProductsState } from "../store_types/products.types";
import { RootState } from "../store_types/index.types";
import {
    AdditionalProduct,
    AdditionalProductsGroup,
    CartProduct,
    MenuCategory,
    MenuProduct,
    MenuStopList,
} from "../util/api.types";
import Product from "../models/Product";
import { canUseEcommerce } from "../service/ecommerce";
import localStorageHelper from "../service/local-storage-helper/index";
import { LSAdditionalProductExceedItem } from "../service/local-storage-helper/types";
import config from "../config";
import { resizeProductImages } from "../service/image-resize-helper/utils";
import { getContext } from "../service/context-keeper";
import {
    decreaseAdditionalProductsLocalExceed,
    findProductById,
    getProductCart,
    getStepCount,
    increaseAdditionalProductsLocalExceed,
} from "../store_helpers/products.helpers";
import { cloneDeep } from "~/util/clone";
import {
    DEFAULT_CITY_ID,
    DEFAULT_CLOSEST_DEPARTMENTS,
    DEFAULT_DELIVERY_TYPE,
    DEFAULT_DEPARTMENT_ID,
} from "~/util/types";
import cityLinksHelper from "~/util/city-links-helper";
import { CatalogItemType } from "~/service/catalog-router/types";
import { DEFAULT_COOKIES_OPTIONS } from "~/service/cookies-helper";
import { CatalogFeaturedProductsFilter } from "~/service/catalog-featured-products";
import { CatalogGetter } from "~/service/catalog-getter";
import { CatalogSorterByGuestPreferences } from "~/service/catalog-sorter-by-guest-preferences";

export const state: () => ProductsState = () => ({
    // metadata: {

    // },
    zeroAdditionalsAccepted: false,
    recommendations: null,
    categories: null,
    products: null,
    stopList: null,
    urls: {},
    metadata: {},
    catalogView: {
        view: "tile",
        screenWidth: null,
    },
    additionalExceed: {},
    noticedProduct: null,
    nextScrollSpeed: null,
    viewOpacity: 0,
    currentViewCategoryId: null,
    currentSort: "popularity",
    isSearchHide: false,
    anotherMenu: 251,
    currentProductEnergyValue: {},
    markupForModal: {},
    refresh: 0,

    searchHistory: [],
    modifiersErrors: [],
    recommendWaitToAdd: null,
});

export const getters: GetterTree<ProductsState, RootState> = {
    cartProducts: (state, getters, rootState, rootGetters): CartProduct[] => {
        return (rootGetters.cart.products || []).filter((prod) => prod.quantity > 0);
    },
    productsModels: (state, getters, rootState, rootGetters): Product[] => {
        return getters.cartProducts.map((prod) => new Product(prod));
    },
    catalogProductModels: (state): Product[] => {
        return (state.products || []).map((prod) => new Product(prod));
    },
    productsModelsByKitchen: (state, getters, rootState, rootGetters) => {
        const groups: { [key: string]: Product[] } = {};

        for (const product of getters.productsModels) {
            const kitchenId: string | number = product.kitchen;
            if (groups[kitchenId] === undefined) {
                groups[kitchenId] = [];
            }
            groups[kitchenId].push(product);
        }

        return groups;
    },
    additionlalProductMap: (state, getters, rootState, rootGetters) => {
        const products: AdditionalProduct[] = rootGetters.cart.additional_products.flatMap((ap) => ap.products);
        const output = {};

        for (const prod of products) {
            output[prod.id] = prod.selected_count;
        }

        return output;
    },
    currentSort: (state) => state.currentSort,
    recommendations: (state) => state.recommendations,
    getCategories(state) {
        return state.categories || [];
    },
    getStopList(state) {
        return state.stopList || [];
    },
    getProductFullInfo: (state) => (id: number) => {
        if (!state.products) {
            return null;
        }
        return findProductById(state.products, id);
    },
    getProductEnergyInfo(state) {
        return state.currentProductEnergyValue;
    },
    getMarkupForModal(state) {
        return state.markupForModal;
    },

    getSearchHistory(state) {
        return state.searchHistory;
    },

    getModifiersErrors(state) {
        return state.modifiersErrors;
    },

    getRecommendWaitToAdd(state) {
        return state.recommendWaitToAdd;
    },
};

export const mutations: MutationTree<ProductsState> = {
    setMetadata(state, metadata) {
        state.metadata[metadata.id] = Object.assign({}, state.metadata[metadata.id], metadata);
        // Vue.set(state.metadata, metadata.id, newMetadata);
    },
    setZeroAdditionalsAccepted(state, value) {
        state.zeroAdditionalsAccepted = value;
    },

    setRecommendations(state, value) {
        state.recommendations = value;
    },

    updateCurrentSort(state, sortName) {
        state.currentSort = sortName;
    },

    setCategories(state, categories) {
        state.categories = categories;
    },
    setProducts(state, products) {
        state.products = products;
    },
    setStopList(state, stop) {
        state.stopList = stop;
    },
    setUrls(state, urls) {
        state.urls = urls;
    },
    updateFullProductData(state: ProductsState, { product, data }) {
        // Vue.set(product, 'possible_kitchen_comments', data.possible_kitchen_comments)
        if (!state.products) {
            return;
        }

        const foundProduct = findProductById(state.products, data.id);
        if (!foundProduct) {
            return;
        }

        // Модификаторы отдельно разберём
        if (foundProduct.available_modifiers && data.available_modifiers) {
            for (const mod of foundProduct.available_modifiers) {
                const newMod = data.available_modifiers.find((am) => am.id == mod.id);
                if (!newMod) {
                    continue;
                }
                Object.assign(mod, newMod);
            }
        }
        delete data.available_modifiers;
        delete data.photo;

        Object.assign(foundProduct, data);
    },
    setViewCatalog(state, payload) {
        state.catalogView = payload;
    },
    setAdditionalExceed(state, value) {
        state.additionalExceed = value;
    },
    setNextScrollSpeed(state, value: number | null = null) {
        state.nextScrollSpeed = value;
    },
    makeNotification(state, notifyProduct) {
        state.noticedProduct = notifyProduct;
    },
    setViewOpacity(state, value: number) {
        state.viewOpacity = value;
    },
    setCurrentViewCategoryId(state, value: number | null = null) {
        state.currentViewCategoryId = value;
    },
    setDefaultcurrentProductEnergyValue(state) {
        state.currentProductEnergyValue = {};
    },
    setCurrentProductEnergyValue(state, payload) {
        if (payload) {
            state.currentProductEnergyValue = payload.product;

            for (const key in state.currentProductEnergyValue) {
                if (!state.currentProductEnergyValue[key]) {
                    delete state.currentProductEnergyValue[key];
                }
            }
        } else {
            state.currentProductEnergyValue = {};
        }
    },
    setDefaultMarkupForModal(state) {
        state.markupForModal = {};
    },
    invalidate(state, val) {
        state.refresh = state.refresh + 1;
    },

    setSearchHistory(state, payload) {
        state.searchHistory = payload;
    },
    addToSearchHistory(state, payload) {
        if (!state.searchHistory.find((item) => item === payload)) {
            state.searchHistory.push(payload);
        }
    },
    delForSearchHistory(state, payload) {
        const itemIndex = state.searchHistory.findIndex((item) => item === payload);

        state.searchHistory.splice(itemIndex, 1);
    },

    setModifiersErrors(state, payload) {
        if (payload) {
            state.modifiersErrors = payload;
        } else {
            state.modifiersErrors = [];
        }
    },

    setRecommendWaitToAdd(state, payload) {
        if (payload) {
            state.recommendWaitToAdd = payload;
        } else {
            state.recommendWaitToAdd = null;
        }
    },
    setSearchHide(state, payload) {
        state.isSearchHide = payload;
    },
};

export const actions: ActionTree<ProductsState, RootState> = {
    changeViewCatalog({ commit }, payload) {
        this.$cookies.set("viewCatalog", payload.view, DEFAULT_COOKIES_OPTIONS);
        this.$cookies.set("userScreenWidth", payload.screenWidth, DEFAULT_COOKIES_OPTIONS);
        commit("setViewCatalog", payload);
    },
    // init ({ commit, dispatch , state, rootState}, payload) {

    // },
    async updateProduct(
        { commit, dispatch, state, rootState, rootGetters, getters },
        {
            product,
            quantity,
            modifiers,
            kitchen_comments,
        }: {
            product: CartProduct | MenuProduct;
            quantity: number;
            modifiers?: MenuProduct[];
            kitchen_comments: Number[];
        }
    ) {
        if (!rootState.isOnline) {
            dispatch("showModalOnOffline", null, { root: true });
            return;
        }

        const prodId = product.id;

        const countStep = getStepCount(product);
        quantity = Math.round(Math.round(quantity / countStep) * countStep * 1000) / 1000;
        quantity = Math.max(quantity, 0);

        const cartProduct: CartProduct | undefined = getProductCart(rootGetters["cart/productsInCart"], product);
        const initValue = cartProduct?.quantity || 0;

        if (initValue == 0) {
            commit("makeNotification", product);
        }

        let changeCnt = quantity - initValue;
        changeCnt = Math.round(changeCnt * 1000) / 1000;

        const metadata = getEcommerceProductData(product);
        commit("setMetadata", metadata);

        const maxQuantity = cartProduct && cartProduct.max_quantity ? cartProduct.max_quantity : -1;
        if (maxQuantity !== -1 && quantity > maxQuantity) {
            if (changeCnt < 0) {
                quantity = maxQuantity;
            } else {
                dispatch(
                    "errors/addDisplayError",
                    {
                        errors: { ["products.update" + prodId]: "Осталось " + maxQuantity },
                        timeout: 1000,
                    },
                    { root: true }
                );

                return;
            }
        }

        const productData: CartProduct = {
            id: prodId,
            name: product.name,
            quantity,
            max_quantity: maxQuantity,
            count_step: countStep,
            queueKey: prodId,
            price: product.price,
            modifiers: product.modifiers,
            local: true,
        };

        commit("order/setLastOrder", null, { root: true });
        commit("cart/setProductData", productData, { root: true });
        dispatch("wait/start", "cart.update_product", { root: true });

        const prodModifiers = orderBy(
            (productData.modifiers || []).map((m) => (typeof m.id === "string" ? parseInt(m.id) : m.id))
        ).join("_");

        try {
            dispatch("cart/changeAmountClean", null, { root: true });

            const resp = await this.$postCartQueue.add(
                "cart/product",
                {
                    id: "" + productData.id,
                    quantity: productData.quantity,
                    modifiers: productData.modifiers,
                    kitchen_comments,
                    queueKey: `${productData.id}_${prodModifiers}`,
                },
                500
            );

            if (this.$router.currentRoute?.params?.step == "products") {
                dispatch("cart/getEcoAvailable", null, { root: true });
            }
        } catch (err) {
            const error_code = err.error_code;
            const message = err.error_message;

            if (error_code == "no_order") {
                await dispatch("cart/clearCart", null, { root: true });
                dispatch("cart/loadCart", null, { root: true });
            }

            if (error_code == "product_schedule") {
                // Открытие модалки на удаление конфлектующих товаров

                dispatch(
                    "modals/openModal",
                    {
                        modalName: "ProductSchedule",
                        modalData: {
                            text: message,
                            productAdd: {
                                id: "" + productData.id,
                                quantity: productData.quantity,
                                modifiers: productData.modifiers,
                                kitchen_comments,
                                queueKey: `${productData.id}_${prodModifiers}`,
                            },
                        },
                        componentProps: { size: "l" },
                    },
                    { root: true }
                );
            } else {
                dispatch(
                    "modals/openModal",
                    {
                        modalName: "InfoModal",
                        modalData: {
                            text: err.error_message || "Неизвестная ошибка изменения корзины",
                            confirmation: false,
                        },
                    },
                    { root: true }
                );
            }

            return;
        } finally {
            if (!this.$postCartQueue.containsKey("cart/product")) {
                dispatch("wait/end", "cart.update_product", { root: true });
            }
        }

        if (canUseEcommerce()) {
            // @ts-ignore
            window.Ecommerce.addProduct(
                getEcommerceProductData(state.metadata[product.id]),
                changeCnt
            ); /* eslint-disable-line dot-notation */
        }
    },
    async loadCatalogOnServer({ commit, rootState }) {
        const result = await CatalogGetter.createFromContext(this).setGuestToken(rootState.auth.token).get();

        if (!result) {
            return;
        }

        const categories: MenuCategory[] = result.categories || [];
        const products: MenuProduct[] = result.products || [];
        const stopList: MenuStopList = result.stop_list || [];

        // Исправим микросервисные категории как flatten_categories=1
        const notFlattened = categories.findIndex((c) => c.parent_id && c.parent_id > 0) >= 0;
        if (notFlattened) {
            for (let i = categories.length - 1; i >= 0; i--) {
                const category = categories[i];
                if (!category.parent_id || category.parent_id == 0) {
                    categories.splice(i, 1);
                    continue;
                }
                category.parent_id = 0;
            }
        }

        const isMetrikaB = rootState.metrika.paramsAB === "B";
        const isResize = config.USE_IMAGE_RESIZER == "1" && !process.client;

        for (const product of products) {
            // Если metrika->paramAB = B, подменим картинку на вторую, если есть
            // Согласовать с СУ: ApiCartProducts::getProductInfo()
            if (isMetrikaB && product.photos && product.photos.length > 1) {
                product.photo = product.photos[1];
            }

            // Ресайз картинок
            if (isResize) {
                resizeProductImages(product);
                for (const modifier of product.available_modifiers || []) {
                    resizeProductImages(modifier);
                }
            }
        }

        // Категория, продукты которой нужно отображать под баннерами перед меню
        const featuredProducts = new CatalogFeaturedProductsFilter(categories, products).getFeatured();
        if (featuredProducts.length > 0) {
            const recommendProducts = { products: featuredProducts };
            commit("promo/setRecommendation", recommendProducts, { root: true });
        }

        commit("setCategories", categories);
        commit("setStopList", stopList);
        commit("setProducts", products);
    },
    async loadCatalogOnClient({ commit, state, dispatch, rootState }) {
        await dispatch("loadCatalogOnServer");

        const ctx = await getContext();

        if (ctx && ctx.app.store) {
            await this.$catalogRouter.fillByVuexStore(ctx.app.store);
            // для обновления ссылки в каталоге
            commit("invalidate");
        }
    },
    async sortCatalogByGuestPreferences({ state, commit, rootState }, payload: null | { context: Context }) {
        // TODO: UUID or Token??
        const uuid = rootState.uuid;
        const auth = rootState.auth.token;

        let guestToken = uuid;
        // for testing purposes
        if (payload && payload.context && payload.context.query.sort_uuid) {
            guestToken = payload.context.query.sort_uuid as string;
        }

        console.log(guestToken);
        // console.log(payload);

        if (!guestToken) {
            return;
        }

        if (!state.categories || state.categories.length === 0 || !state.products || state.products.length === 0) {
            return;
        }

        const sorter = new CatalogSorterByGuestPreferences(guestToken, state.categories, state.products);
        await sorter.sort();

        if (sorter.isSuccess() && sorter.getIsGuestExperimental()) {
            commit("setCategories", sorter.getResponseCategories());
            commit("setProducts", sorter.getResponseProducts());
        }

        const paramsXY = sorter.getXYMetrikaValue();
        if (paramsXY) {
            commit("metrika/setParamsXY", paramsXY, { root: true });
        }
    },
    async loadCatalog({ dispatch }, payload) {
        const canWait = this.hasModule("wait");
        if (canWait) {
            dispatch("wait/start", "catalog", { root: true });
        }

        if (process.client) {
            await dispatch("loadCatalogOnClient");
        } else {
            await dispatch("loadCatalogOnServer");
        }

        if (canWait) {
            dispatch("wait/end", "catalog", { root: true });
        }

        /* if (!process.client) {
            await dispatch("sortCatalogByGuestPreferences", payload);
        } */
    },
    async loadUrls({ commit }) {
        commit("setUrls", (await new UrlsStorage().get()) || {});
    },
    async addProduct(
        { state, commit, dispatch, rootGetters },
        {
            product,
            isRecommendation,
            arrCommentIds,
        }: {
            product: CartProduct | MenuProduct;
            isRecommendation?: boolean;
            arrCommentIds: Number[];
        }
    ) {
        // console.log("add prod!!", product);
        // get exactly same product in cart
        const cartProduct = getProductCart(rootGetters["cart/productsInCart"], product);

        const initValue = cartProduct?.quantity || 0;
        const step = getStepCount(product);

        await dispatch("updateProduct", {
            product,
            quantity: initValue + step,
            kitchen_comments: arrCommentIds,
        });
    },
    async addProducts({ commit, dispatch }, products) {
        try {
            await this.$postCartQueue.add(
                "cart/products",
                {
                    products,
                    replace: true,
                },
                500
            );
        } catch (err) {
            const message = err.error_message || "Неизвестная ошибка изменения корзины";
            dispatch(
                "modals/openModal",
                {
                    modalName: "InfoModal",
                    modalData: {
                        text: message,
                        confirmation: false,
                    },
                },
                { root: true }
            );
        }
    },

    async removeProduct({ dispatch, rootGetters }, product: CartProduct | MenuProduct) {
        // get exactly same product in cart
        const cartProduct = getProductCart(rootGetters["cart/productsInCart"], product);

        const initValue = cartProduct?.quantity || 0;

        const step = getStepCount(product);

        await dispatch("updateProduct", {
            product,
            quantity: initValue - step,
        });
    },
    async loadProductById({ commit, rootGetters, dispatch }, product) {
        // Сначала ждём, когда клиентская часть приложения пройдёт необходимые этапы инициализации
        if (rootGetters.clientInitializedEvent) {
            await rootGetters.clientInitializedEvent;
        }

        const productData = await this.$api
            .get(`menu/product/${product.id}`, { with_recommended: "1", is_attractive_offer: "1" })
            .catch((err) => dispatch("errors/addPopupError", err?.error_message, { root: true }));
        commit("updateFullProductData", {
            product,
            data: productData,
        });

        return productData;
    },
    deleteProduct({ dispatch }, product: MenuProduct) {
        if (product.sel_modifiers) {
            product.modifiers = product.sel_modifiers.map((item) => {
                return { id: item.id, quantity: 1 };
            });
        }

        return dispatch("updateProduct", {
            product,
            quantity: 0,
        });
    },

    async updateAdditionalProduct(
        { commit, dispatch, getters, rootState, rootGetters },
        payload: { product: AdditionalProduct; quantity: number; force: boolean }
    ) {
        if (!rootState.isOnline) {
            dispatch("showModalOnOffline", null, { root: true });
            return;
        }

        const product: AdditionalProduct = payload.product;
        let quantity: number = payload.quantity;

        const group: AdditionalProductsGroup = rootGetters.cart.additional_products.find((group) =>
            group.products.some((p) => p.id == product.id)
        );
        const notAllowedExcess = group && group.free_count && !group.add_price;

        if (notAllowedExcess && quantity > group.free_count) {
            quantity = group.free_count;
        }

        // rootGetters.cart.additional_products.map((group) => {
        //     if(group.free_count && !group.add_price &&
        //         group.products.some(p => p.id == product.id) && quantity > group.free_count
        //     ) {
        //         quantity = group.free_count;
        //     }
        // });

        await dispatch("changeAdditionalProductsLocalExceed", {
            product,
            quantity,
        });

        const additionalMap = { ...getters.additionlalProductMap };
        additionalMap[product.id] = quantity;

        if (payload.force) {
            this.$postCartQueue.add(
                "cart/additionalProducts",
                { id: "additional", additional_products: additionalMap },
                500
            );
        }

        commit("cart/setAdditionalProductCnt", additionalMap, { root: true });
    },
    async updateAdditionalProducts({ getters }) {
        const additionalMap = { ...getters.additionlalProductMap };
        await this.$postCartQueue.add(
            "cart/additionalProducts",
            { id: "additional", additional_products: additionalMap },
            500
        );
    },
    async changeAdditionalProductsLocalExceed(
        { commit, rootGetters },
        { product, quantity }: { product: AdditionalProduct; quantity: number }
    ) {
        const group: AdditionalProductsGroup = rootGetters.cart.additional_products.find((group) =>
            group.products.some((p) => p.id == product.id)
        );
        const groupSelectedCount = group.products.reduce((sum, item) => sum + item.selected_count, 0);

        const productInGroup: AdditionalProduct | undefined = group.products.find((p) => p.id == product.id);
        const beforeCount = productInGroup ? productInGroup.selected_count : 0;
        const diffCount = quantity - beforeCount;

        const lsProducts: LSAdditionalProductExceedItem =
            cloneDeep(await localStorageHelper.getValue(LOCAL_STORAGE_ADDITIONAL_PRODUCTS_KEY)) || {};

        if (diffCount > 0 && groupSelectedCount + diffCount > group.free_count) {
            increaseAdditionalProductsLocalExceed(lsProducts, diffCount, product.id);
        } else if (diffCount < 0) {
            decreaseAdditionalProductsLocalExceed(lsProducts, diffCount, product.id, group);
        }

        await localStorageHelper.setValue(LOCAL_STORAGE_ADDITIONAL_PRODUCTS_KEY, lsProducts);
        commit("setAdditionalExceed", lsProducts);
    },
    async adjustAdditionalProductsLocalExceed({ commit, rootGetters }) {
        const lsProducts: LSAdditionalProductExceedItem =
            cloneDeep(await localStorageHelper.getValue(LOCAL_STORAGE_ADDITIONAL_PRODUCTS_KEY)) || {};
        const localKeysMap: { [key: string]: boolean } = {};

        // console.log('** beforeValue', cloneDeep(lsProducts));

        // Проверим каждую группу на несоответствие превышения
        for (const group of rootGetters.cart.additional_products) {
            if (group && group.free_count && !group.add_price) {
                continue;
            }

            // Соберём суммарное превышение в корзине для группы
            const groupSelectedCount = group.products.reduce((sum, item) => sum + item.selected_count, 0);
            const cartExceedCount = groupSelectedCount - group.free_count;

            // Соберём суммарное превышение в localStorage для тех же product.id
            let localCount = 0;
            for (const groupProduct of group.products) {
                if (lsProducts[groupProduct.id] !== undefined) {
                    localCount += lsProducts[groupProduct.id];
                    localKeysMap[groupProduct.id] = true;
                }
            }

            let diffCount = cartExceedCount - localCount;

            const firstProductId = group.products[0].id;

            if (diffCount > 0) {
                // Прибавляем в localStorage только исходя из соответствующих выбранных значений доп.продуктов
                for (const groupProduct of group.products) {
                    const alreadyExceed = lsProducts[groupProduct.id] || 0;
                    const plusValue = Math.max(0, Math.min(groupProduct.selected_count - alreadyExceed, diffCount));

                    increaseAdditionalProductsLocalExceed(lsProducts, plusValue, groupProduct.id);
                    diffCount -= plusValue;

                    localKeysMap[groupProduct.id] = true;

                    if (diffCount <= 0) {
                        break;
                    }
                }
            } else if (diffCount < 0) {
                decreaseAdditionalProductsLocalExceed(lsProducts, diffCount, firstProductId, group);
            }
        }

        // console.log('** afterValue', cloneDeep(lsProducts));

        // Неиспользованные ключи хотя бы в одной из групп удалим из localStorage
        for (const key of Object.keys(lsProducts)) {
            if (!localKeysMap[key]) {
                delete lsProducts[key];
            }
        }

        await localStorageHelper.setValue(LOCAL_STORAGE_ADDITIONAL_PRODUCTS_KEY, lsProducts);
        commit("setAdditionalExceed", lsProducts);
    },
    async addAdditionalProduct({ dispatch }, payload: { product: AdditionalProduct; force: boolean }) {
        await dispatch("updateAdditionalProduct", {
            product: payload.product,
            quantity: payload.product.selected_count + 1,
            force: payload.force,
        });
    },
    async removeAdditionalProduct({ dispatch }, payload: { product: AdditionalProduct; force: boolean }) {
        if (payload.product.selected_count == 0) {
            return;
        }
        await dispatch("updateAdditionalProduct", {
            product: payload.product,
            quantity: payload.product.selected_count - 1,
            force: payload.force,
        });
    },
    resetAdditionalProducts({ getters, commit }) {
        const additionalMap = { ...getters.additionlalProductMap };

        for (const key in additionalMap) {
            additionalMap[key] = 0;
        }

        commit("cart/setAdditionalProductCnt", additionalMap, { root: true });
    },

    validate({ state, commit, dispatch, rootState, rootGetters }) {
        dispatch("errors/clearError", "additional_products_fail", { root: true });
        dispatch("errors/clearError", "products.zeroAdditionals", { root: true });

        if (rootState.siteId == "jm") {
            return;
        }

        const groups = rootState.cart.content?.additional_products;
        if (!groups) {
            return;
        }

        let totalCount = 0;
        groups.forEach((el) => {
            const countProduct = el.products.reduce((total, value) => {
                return total + value.selected_count;
            }, 0);
            totalCount += countProduct;
        });

        if (groups.length && totalCount <= 0 && !state.zeroAdditionalsAccepted) {
            dispatch("modals/openModal", { modalName: "ForgottenProducts" }, { root: true });
            return Promise.reject({
                "products.zeroAdditionals": "Выберите дополнительные компоненты из каждой группы",
                additional_products_fail: "Выберите дополнительные компоненты из каждой группы",
            });
        }

        // // Проверять в каждой группе по отдельности
        // for (let i = 0; i < groups.length; i++) {
        //     const prods: AdditionalProduct[] = groups[i].products;
        //     let isEmpty = true;
        //
        //     for (let j = 0; j < prods.length; j++) {
        //         if (prods[j].selected_count > 0) {
        //             isEmpty = false;
        //             break;
        //         }
        //     }
        //     const doNotNeedAdditional =
        //         rootState.order.needAdditionalProducts[groups[i].id] != undefined
        //             ? rootState.order.needAdditionalProducts[groups[i].id]
        //             : true;
        //     if (isEmpty && !state.zeroAdditionalsAccepted && doNotNeedAdditional) {
        //         // Создаём объект модалки с пропсами
        //         dispatch("modals/openModal", { modalName: "ForgottenProducts" }, { root: true });
        //         return Promise.reject({
        //             "products.zeroAdditionals": "Выберите дополнительные компоненты из каждой группы",
        //             additional_products_fail: "Выберите дополнительные компоненты из каждой группы",
        //         });
        //     }
        // }
    },
    async approveIngredientChange({ commit, dispatch }, changeIds) {
        dispatch("wait/start", "cart.payment.ingridientChange", { root: true });
        const resp = await this.$postCartQueue.add("cart/ingredientChangeApproval", { id: changeIds }, 100);
        dispatch("wait/end", "cart.payment.ingridientChange", { root: true });

        return resp;
        // this.$store.dispatch('products/approveIngredientChange', changeId);
    },
    async getRecommendations({ commit }) {
        const resp = await this.$api.get("menu/recommendedProducts", { availability_all: 1 });
        await commit("setRecommendations", resp);
    },
    switchCurrentSort({ commit }, sortName) {
        commit("updateCurrentSort", sortName);
    },
    findCurrentViewCategoryId({ commit }, route: Route | null = null) {
        commit("setCurrentViewCategoryId", null);

        const path = route ? route.path : this.$router?.currentRoute?.path;
        if (!path) {
            return;
        }

        const catalogItem = this.$catalogRouter.getItemByPath(cityLinksHelper.cleanCityPath(path));
        if (!catalogItem) {
            return;
        }
        if (catalogItem.type !== CatalogItemType.CATEGORY) {
            return;
        }
        commit("setCurrentViewCategoryId", catalogItem.id);
    },

    async checkDeliveryChanged({ dispatch, rootGetters }) {
        if (!rootGetters.cart) {
            return;
        }
        let isChanged = false;
        const checks = [
            // [ cookie_key, cart_key, default_value ]
            ["city_id", "cityId", "" + DEFAULT_CITY_ID],
            ["department_id", "cartDepartmentId", "" + DEFAULT_DEPARTMENT_ID],
            ["delivery_type", "deliveryType", DEFAULT_DELIVERY_TYPE],
            ["multi_departments", "closestDepartmentsIdStr", DEFAULT_CLOSEST_DEPARTMENTS],
        ];

        for (const check of checks) {
            const [cookieKey, cartKey, defaultValue] = check;

            const cookieValue = this.$cookies.get(cookieKey) || defaultValue;
            const cartValue = rootGetters[`cart/${cartKey}`] || defaultValue;

            if (cookieValue != cartValue) {
                isChanged = true;
                this.$cookies.set(cookieKey, cartValue, DEFAULT_COOKIES_OPTIONS);
            }
        }

        if (isChanged) {
            await dispatch("loadCatalog");
            await dispatch("promo/loadBanners", null, { root: true });
        }
    },
    changeCurrentProductEnergyValue({ commit }, payload) {
        if (payload) {
            commit("setCurrentProductEnergyValue", payload);
        } else {
            commit("setDefaultcurrentProductEnergyValue");
        }
    },
    // changeMarkupForModal({ commit }, payload) {
    //     if (payload) {
    //         commit("setMarkupForModal", payload);
    //     }
    //     else {
    //         commit("setDefaultMarkupForModal");
    //     }
    // },
};
