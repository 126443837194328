//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { sendYandexMetric } from "~/service/ecommerce";
import { isEmpty } from "~/util/helpers";
import Container from "~/components/common/Container.vue";
import LogoSmall from "~/components/icons/LogoSmall.vue";
import launchCityHelper from "~/service/launch-city-helper";
// import LogoNg from "~/components/icons/LogoNg.vue";
// import LogoNgSmall from "~/components/icons/LogoNgSmall.vue";
import Logo from "~/components/icons/Logo.vue";
import AddressMapIcon from "~/components/icons/AddressMapIcon.vue";
// import LogoWomenDay from "~/components/icons/LogoWomenDay.vue";
// import LogoLove from "~/components/icons/LogoLove.vue";

export default {
    name: "TopLine",
    components: { Logo, LogoSmall, Container, AddressMapIcon },
    props: ["menu"],
    data() {
        return {
            importedComponent: null,
            addressSelectorShown: false,
            animation: false,
            openMenu: false,
            showSanta: false,
            isLoadingGeo: false,
            isShowTooltip: false,
        };
    },
    computed: {
        ...mapState("delivery", ["city", "cities", "cityViaIp", "mapClickLoading"]),
        ...mapState("modals", ["properties"]),
        // ...mapState(["newYearAnimation"]),
        ...mapGetters("modals", ["getOpenedModal"]),
        ...mapState("account", ["showMobileAppBanner", "profile"]),
        ...mapGetters("account", ["addresses"]),
        ...mapGetters("auth", ["isLoggedIn"]),
        ...mapGetters("cart", ["deliveryType", "deliveryDepartmentId", "deliveryAddress", "isCartLoaded"]),
        ...mapGetters("rateProducts", ["getPopupOrder", "getOrdersPopedUp"]),
        ...mapGetters("promo", ["cancelledPromo"]),
        ...mapGetters("products", ["getCategories"]),
        ...mapGetters("delivery", [
            "deliveryType",
            "deliverySelfDepartemnt",
            "deliveryAddress",
            "fullEmptyDelivery",
            "emptyDeliverySelf",
            "emptyDeliveryAddress",
            "currentGeoPosition",
        ]),
        isMobile() {
            return this.$screen.width < 800;
        },
        needIndent() {
            if (process.browser) {
                if (window.innerWidth <= 480) {
                    return this.showMobileAppBanner && !this.anyOpenModals;
                }
            }
            return false;
        },
        canOpenModal() {
            return this.$route.fullPath.indexOf("/user/") !== 0 && this.$route.fullPath.indexOf("/order/") !== 0;
        },
        windowWidth() {
            return this.$screen.width;
        },
        isShowCart() {
            if (this.windowWidth < 1024) {
                return this.deliverySelfDepartemnt || Object.keys(this.deliveryAddress).length > 0;
            } else {
                return true;
            }
        },
        newCity() {
            return launchCityHelper.getLaunchCityItemByPath(this.$route.path);
        },
        isShowLogo() {
            return Object.keys(this.deliveryAddress).length === 0 && !this.deliverySelfDepartemnt;
        },
        trigger() {
            return this.$refs.trigger;
        },
        isHomePage() {
            const currentPath = this.$route.path;
            const inCategories = this.getCategories.find(
                (cat) => cat.url === currentPath || currentPath.includes(cat.url)
            );
            return currentPath === "/" || !!inCategories;
        },
        isNewCityPage() {
            return this.$route.name?.includes("new_city");
        },
    },
    watch: {
        profile() {
            if (this.canOpenModal) {
                this.$store.dispatch("rateProducts/fetchPopupOrder").then(() => {
                    if (this.getPopupOrder && !this.getOrdersPopedUp.includes(this.getPopupOrder.id)) {
                        this.openModal({
                            modalName: "RateProductsPopup",
                            componentProps: { size: "xl" },
                        });
                        this.$store.dispatch("rateProducts/orderPopedUp", this.getPopupOrder.id);
                    }
                });
            }
        },
        deliveryType(value) {
            localStorage.removeItem("cancelledPromos");
        },
        cancelledPromo(value) {
            let displayCancelled = value;
            const localCancelledPromo = JSON.parse(localStorage.getItem("cancelledPromos"));
            if (localCancelledPromo && localCancelledPromo.length) {
                displayCancelled = displayCancelled.filter((display) => !localCancelledPromo.includes(display.id));
            }

            if (!isEmpty(value) && displayCancelled.length) {
                setTimeout(() => {
                    this.openModal({
                        modalName: "PromoCancelledModal",
                        modalData: { displayCancelled },
                    });
                }, 0);
            }
        },
        isCartLoaded() {
            if (this.emptyDeliveryAddress) {
                this.setStartGeo();
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.showSanta = true;
        }, 0);
    },
    methods: {
        ...mapActions("modals", ["openModal", "closeModal"]),
        ...mapActions("delivery", ["fetchGeoPosition", "setDeliveryAddress"]),
        ...mapMutations("modals", ["setSearchOpen"]),
        toggleMenu() {
            this.$emit("toggleMenu");
            this.openMenu = !this.openMenu;
        },
        close() {
            setTimeout(() => {
                this.closeModal();
            }, 100);
        },
        hideTooltip() {
            this.isShowTooltip = false;
        },
        showMap() {
            this.openModal({
                modalName: "DeliveryMap",
                modalData: { chosenCity: this.selectedCity },
            });
        },
        async setStartGeo() {
            if (this.fullEmptyDelivery) {
                // Получение адресов прошлых доставок
                if (this.isLoggedIn && !this.addresses) {
                    await this.$store.dispatch("account/loadAddresses");
                }

                sendYandexMetric("choice-geolocation");
                await this.fetchGeoPosition()
                    .then(async (rez) => {
                        // Пользователь дал свое гео
                        sendYandexMetric("share-geolocation");
                        await this.setDeliveryAddress(this.currentGeoPosition);
                        if (this.city.id !== rez.city_id) {
                            await this.$store.dispatch("delivery/reloadByCityId", { cityId: rez.city_id });
                        }
                    })
                    .catch(async (err) => {
                        // Пользователь отказался дать свое гео
                        sendYandexMetric("dont-share-geolocation");
                        console.warn("Пользователь отказался предоставить гео");
                        console.error(err);
                        if (this.isLoggedIn && this.addresses.length > 0) {
                            await this.setDeliveryAddress(this.addresses[0]);
                            if (this.city.id !== this.addresses[0].city_id) {
                                await this.$store.dispatch("delivery/reloadByCityId", {
                                    cityId: this.addresses[0].city_id,
                                });
                            }
                        }
                    });
            } else {
                await this.fetchGeoPosition().catch((err) => {
                    console.warn("Получаю geo пользователя - позиция вне зоны доставки", err);
                    // console.error(err);
                });
            }

            if (this.isLoggedIn && this.addresses.length > 0) {
                const coordsMatchAccountAddress =
                    this.currentGeoPosition?.lat !== this.addresses[0].lat &&
                    this.currentGeoPosition?.lon !== this.addresses[0].lon;
                this.isShowTooltip = this.currentGeoPosition ? coordsMatchAccountAddress : true;
            }
        },
    },
};
