import { render, staticRenderFns } from "./NavSoc.vue?vue&type=template&id=2e7b0fb9&scoped=true&"
import script from "./NavSoc.vue?vue&type=script&lang=js&"
export * from "./NavSoc.vue?vue&type=script&lang=js&"
import style0 from "./NavSoc.vue?vue&type=style&index=0&id=2e7b0fb9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e7b0fb9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Vk: require('/usr/src/app/components/icons/Vk.vue').default,Telegram: require('/usr/src/app/components/icons/Telegram.vue').default})
